<template>
    <div class="dashboard" :style="`--var-element-color: ${ publicSettings.element_color }; --var-element-text-color: ${ publicSettings.element_text_color }; --var-timer-color: ${ publicSettings.timer_color }; --var-submit-btn-color: ${publicSettings.submit_btn_bgcolor}; --var-submit-btn-disabled-color: ${publicSettings.submit_btn_bgcolor}80; --var-submit-btn-txt-color: ${publicSettings.submit_btn_textcolor}; --var-block-bg-color: ${publicSettings.block_bg_color}`">
        <div class="client_card">
            <div v-show="publicSettings.display_header == 1" class="card_header pointer"  ref="hdrsection" :style="`background: ${publicSettings.header_bgcolor}; color: ${publicSettings.header_textcolor};`">
                <nav :class="{'workout_bar' : navTab != 'workout'}">
                    <button type="button" class="back_btn" @click="handleExit('plan')" v-if="navTab === 'workout' && isPlan"><i class="fas fa-chevron-left"></i>Program</button>
                    <button type="button" class="leave_btn" @click="handleExit('workout')" v-if="navTab != 'workout'"><i class="fas fa-times"></i></button>
                    <img class="logo_img" v-if="publicSettings.display_logo && navTab == 'workout'" :src="publicSettings.logo ? publicSettings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a v-if="navTab == 'workout'">
                        <h4>Dashboard</h4>
                    </a>
                </nav>
                <div class="block_status" v-if="workoutStarted">
                    {{blockExerciseIndex + 1}} of {{ selectedWorkoutBlock.exercises.length}}
                </div>
                <div class="more_details" @click.stop="skipExerciseMoreDetails = !skipExerciseMoreDetails; skipExerciseBlock = false;" v-if="navTab == 'details' && selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length"><i class="fas fa-ellipsis-h"></i></div>
            </div>
        </div>
        <div class="dashboard_content">
            <div class="form_area_video" v-if="navTab == 'workout' && !isPlan">
                <div class="section_item" :class="device == 'cell' ? '' : 'mb-3'" ref="expvidsection" v-if="publicSettings.display_explainer_video && ((publicSettings.explainer_video && publicSettings.explainer_video_type == 0) || (publicSettings.explainer_embed && publicSettings.explainer_video_type == 1))">
                    <div class="video_wpr">
                        <div v-if="publicSettings.explainer_video_type == 0" v-html="parseEmbedCode(publicSettings.explainer_video)"></div>
                        <div v-if="publicSettings.explainer_video_type == 1" v-html="publicSettings.explainer_embed"></div>
                    </div>
                </div>
            </div>
            <div class="dashboard_container">
                <div class="exercise_list" v-if="navTab == 'workout'">
                    <div class="exercise_item" v-show="!exerciseDetail">
                        <div class="instruction pt-2">
                            <h4 class="headline">{{ selectedWorkout.name }}</h4>
                            <p>{{ selectedWorkout.description }}</p>
                            <div class="quick_guide">
                                <ul>
                                    <li>
                                        <h5>{{ selectedWorkout.blocks.length }}</h5>
                                        <h6>Blocks</h6>
                                    </li>
                                    <li>
                                        <h5>{{ workoutStats.rounds }}</h5>
                                        <h6>Rounds</h6>
                                    </li>
                                    <li>
                                        <h5>{{ workoutStats.sets }}</h5>
                                        <h6>Sets</h6>
                                    </li>
                                    <li>
                                        <h5>{{ workoutStats.hours ? workoutStats.hours + ' Hour\'s ' : '' }}{{ workoutStats.minutes ? workoutStats.minutes + ' Min ' : '' }}{{ !workoutStats.hours && !workoutStats.minutes ? (workoutStats.seconds ? workoutStats.seconds + ' Sec ' : '0') : '' }}</h5>
                                        <h6>Duration</h6>
                                    </li>
                                </ul>
                                <button type="button" class="start_btn" @click="handleStartWorkout()">{{isResumeWorkout ? 'Resume Workout' : 'Start Workout'}}</button>
                            </div>
                        </div>
                        <swiper :pagination="{clickable: true}" :navigation="true" class="plan_wrapper" :auto-height="true"  v-if="workout && workout.blocks.length">
                            <swiper-slide v-for="(block, b) in selectedWorkoutBlockList" :key="b">
                                <div class="block_title">{{block.name}} &nbsp;</div>
                                <div class="plan_list">
                                    <div class="block_type"><span class="tag">Perform as a {{block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span></div>
                                    <div class="plan_info" v-if="block.perform_type == 2">
                                        {{block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round} rounds`}}
                                        <label v-if="blockHasWorkTime(block)">&nbsp;| {{ filterWorkTime(block) }} work </label>
                                        <label v-if="block.circuit_settings.rest_min || block.circuit_settings.rest_sec">&nbsp;| {{ filterRestTime(block) }}</label>
                                        <label v-if="block.circuit_settings.amrap && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec)">&nbsp;| {{ block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min} min` : ''  }} {{ block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec} sec` : '' }} duration</label>
                                    </div>
                                    <ul v-if="block.exercises && block.exercises.length">
                                        <li v-for="(exercise, e) in block.exercises" :key="e" @click="handleExerciseDetails(block, exercise, b, e)">
                                            <div class="video_area">
                                                <div class="video_wpr exercise" :class="{ video_wpr_flip: exercise.is_flip}">
                                                    <img :src="exercise.thumb" :alt="exercise.name">
                                                </div>
                                            </div>
                                            <div class="info_wpr">
                                                <h4>{{exercise.name}}</h4>
                                                <h6>
                                                    <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                    <span v-if="block.perform_type != 2">{{ exercise.sets }} sets </span>
                                                    <span v-if="exercise.measure_type != 2">
                                                        <template v-if="exercise.is_reps_ranges">
                                                            &nbsp; {{ exercise.min_reps ? exercise.min_reps : 0 }}-{{ exercise.max_reps ? exercise.max_reps : 0 }} reps
                                                        </template>
                                                        <template v-else>
                                                            &nbsp;{{ exercise.is_failure && !exercise.is_reps_ranges ? 'max' : exercise.exercise_reps }} reps 
                                                        </template>
                                                    </span>
                                                    <span v-if="exercise.measure_type == 2 && exercise.exercise_minutes">&nbsp;{{ exercise.exercise_minutes }} min</span>
                                                    <span v-if="exercise.measure_type == 2 && exercise.exercise_seconds">&nbsp;{{ exercise.exercise_seconds }} sec </span>
                                                    <span v-if="exercise.load_unit_code == '1RM'">
                                                        &nbsp;{{ oneRepMaxCalculations[b] && oneRepMaxCalculations[b][e] && oneRepMaxCalculations[b][e].reps ? Math.round(exercise.load_number * 0.01 * parseInt(oneRepMaxCalculations[b][e].reps)) + ' ' + oneRepMaxCalculations[b][e].weight_unit : `${exercise.load_number}% of 1RM` }}
                                                    </span>
                                                    <span v-else-if="exercise.load_unit_code == 'BW'">&nbsp;bodyweight</span>
                                                    <span v-else>&nbsp;{{ filterLoadNumber(exercise) }} {{ filterLoadUnitCode(exercise) }} </span>
                                                </h6>
                                            </div>
                                            <span class="superset" v-if="block.perform_type == 1 && !exercise.is_rest">SS</span>
                                        </li>
                                    </ul>
                                    <div class="empty_section px-2 mt-2" v-else>
                                        <h1>Looks like you don't have any Exercises yet in this block.</h1>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>

                <div class="exercise_details" v-if="navTab == 'details' && selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length">
                    <div class="instruction pt-0">
                        <h3 class="label_heading" v-if="isRestTimer">Up Next...</h3>
                        <h4 class="headline text-center justify-content-center">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].name }}</h4>
                        <div class="video_wpr exercise" :class="{ video_wpr_flip: selectedWorkoutBlock.exercises[blockExerciseIndex].is_flip}">
                            <div v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].has_gif == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].gif_image" class="player_wpr">
                                <img v-if="selectedWorkoutBlock.exercises[blockExerciseIndex]" :src="selectedWorkoutBlock.exercises[blockExerciseIndex].gif_image" :alt="selectedWorkoutBlock.exercises[blockExerciseIndex].name">
                            </div>
                            <div v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedWorkoutBlock.exercises[blockExerciseIndex].video_link)"></div>
                            <div v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].video_type == 2" class="player_wpr" v-html="selectedWorkoutBlock.exercises[blockExerciseIndex].embed_code"></div>
                        </div>
                    </div>
                    <div class="activity"  ref="calc_section">
                        <div class="instruction pt-0">
                            <div v-if="workoutStarted">
                                <div class="rest_alert" :class="{ has_play_pause_button: selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].sets > 1 }" v-if="(selectedWorkoutBlock.perform_type == 2 || (selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2)) && isExerciseWorkTimer && !isRestTimer && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type !== 1 && selectedWorkoutBlock.has_work_interval">
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">
                                                <h4>Work</h4>
                                                <div>{{workMinLeft < 10 ? `0${workMinLeft}` : workMinLeft}}:{{workSecLeft < 10 ? `0${workSecLeft}` : workSecLeft}}</div>
                                            </div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                                            <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (totalWorkSecLeft * (60 / totalWorkSec))"/>
                                        </svg>
                                    </div>
                                    <template v-if="selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].sets > 1">
                                        <div class="go lg" @click="stopStartWorkCountdown(); stopWorkFlag = !stopWorkFlag;" v-if="workoutStarted && !isRestTimer && (selectedWorkoutBlock.perform_type == 2 || (selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2)) && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type !== 1"><i :class="`fas fa-${stoppedWorkTimer ?  'play' : 'pause' }`"></i></div>
                                    </template>
                                </div>
                                <div class="rest_alert" v-if="isRestTimer">
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">
                                                <h4>Rest</h4>
                                                <div>{{restMinLeft < 10 ? `0${restMinLeft}` : restMinLeft}}:{{restSecLeft < 10 ? `0${restSecLeft}` : restSecLeft}}</div>
                                            </div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                                            <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * ((60 * restMinLeft) + restSecLeft) * (60 / totalRestSec)"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer_area">
                            <h4 class="round_details" v-if="selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].sets > 1 && !supersetStarted">
                                {{ selectedExerciseSetsCount }} of {{ selectedWorkoutBlock.exercises[blockExerciseIndex].sets }} timed sets
                            </h4>
                            <h4 class="round_details" v-else-if="selectedWorkoutBlock.perform_type == 1 && supersetStarted && selectedWorkoutBlock.exercises[blockExerciseIndex].sets > 1">
                                Superset {{ supersetRoundCount }} of {{ supersetExercisesRound }}
                            </h4>
                        </div>
                        <div class="activity mt-4 mb-1" ref="calc_section" v-if="selectedWorkoutBlock.perform_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 1 && !isRestTimer">
                            <div class="instruction pt-0" v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                <h4 class="headline">
                                    <button type="button" class="calc_btn" :class="!oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? 'blink' : ''" @click="repConfirm = !repConfirm; isRestTimer = false;">{{repConfirm ? 'Edit 1RM' : 'Calculate 1RM'}}</button>
                                </h4>
                            </div>
                            <div v-if="selectedWorkoutBlock.perform_type == 2">
                                <table>
                                    <thead>
                                        <tr>
                                            <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">Work Time</th>
                                            <th v-else>Reps</th>
                                            <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">
                                                <input class="no_spinner" type="number" min="0" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes">:
                                                <input class="no_spinner" type="number" min="0" max="59" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds">
                                            </td>
                                            <td v-else>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                                <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps : 0}-${selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps : 0}`">
                                                <input v-else type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_reps">
                                            </td>
                                            <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].tempo"></td>
                                            <td>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                                <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                                    <input class="no_spinner" type="number"
                                                        :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedWorkoutBlock.exercises[blockExerciseIndex].load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''"
                                                        :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].load_number"
                                                    >{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit : '% ' + selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                                </div>
                                                <div class="flex justify-content-center" v-else>
                                                    <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedWorkoutBlock.exercises[blockExerciseIndex])" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center">
                                                    <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes) : '00'">:
                                                    <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds) : '00'">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else>
                                <table v-if="!selectedWorkoutBlock.exercises[blockExerciseIndex].is_variable">
                                    <thead>
                                        <tr>
                                            <th class="set">Sets</th>
                                            <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">Work Time</th>
                                            <th v-else>Reps</th>
                                            <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].sets }}</td>
                                            <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">
                                                <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes" disabled>:
                                                <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds" disabled>
                                            </td>
                                            <td v-else>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                                <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps : 0}-${selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps : 0}`">
                                                <input v-else type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_reps">
                                            </td>
                                            <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].tempo"></td>
                                            <td>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                                <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                                    <input class="no_spinner" type="number"
                                                        :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedWorkoutBlock.exercises[blockExerciseIndex].load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''"
                                                        :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].load_number"
                                                    >{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                                </div>
                                                <div class="flex justify-content-center" v-else>
                                                    <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedWorkoutBlock.exercises[blockExerciseIndex])" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center">
                                                    <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes) : '00'">:
                                                    <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds) : '00'">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-else>
                                    <thead>
                                        <tr>
                                            <th class="set">Sets</th>
                                            <th>Reps</th>
                                            <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(set, s) in selectedWorkoutBlock.exercises[blockExerciseIndex].set_settings" :key="s">
                                            <template v-if="!set.is_disable">
                                                <td class="set">{{ s + 1 }}</td>
                                                <td>
                                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges || set.rep_unit == 'Max Reps'" type="text" value="max" placeholder="max" disabled >
                                                    <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${set.min_reps ? set.min_reps : 0}-${set.max_reps ? set.max_reps : 0}`">
                                                    <input v-else type="number" :placeholder="set.reps">
                                                </td>
                                                <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="set.tempo"></td>
                                                <td>
                                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                                    <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                                        <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="filterLoadNumber(set)">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                                    </div>
                                                    <div class="flex justify-content-center" v-else>
                                                        <input class="no_spinner" type="number" :placeholder="filterLoadNumber(set)" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="flex justify-content-center">
                                                        <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (set.rest_minutes < 10 ? `0${set.rest_minutes}` : set.rest_minutes) : '00'">:
                                                        <input class="no_spinner" type="number" min="0" max="59" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (set.rest_seconds < 10 ? `0${set.rest_seconds}` : set.rest_seconds) : '00'">
                                                    </div>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="(selectedWorkoutBlock.perform_type !== 2 || (selectedWorkoutBlock.perform_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() == '1rm')) && !isRestTimer">
                            <div class="instruction pt-0">
                                <h4 class="headline" v-if="selectedWorkoutBlock.perform_type == 1">
                                    <button type="button" class="calc_btn" :class="!oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? 'blink' : ''" v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'" @click="repConfirm = !repConfirm; isRestTimer = false;">{{repConfirm ? 'Edit 1RM' : 'Calculate 1RM'}}</button>
                                </h4>
                            </div>
                            <table v-if="!selectedWorkoutBlock.exercises[blockExerciseIndex].is_variable">
                                <thead>
                                    <tr>
                                        <th class="set">Sets</th>
                                        <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">Work Time</th>
                                        <th v-else>Reps</th>
                                        <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                        <th>Load</th>
                                        <th>Rest Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="set">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].sets }}</td>
                                        <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">
                                            <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes" disabled>:
                                            <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds" disabled>
                                        </td>
                                        <td v-else>
                                            <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                            <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps : 0}-${selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps : 0}`">
                                            <input v-else type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_reps">
                                        </td>
                                        <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].tempo"></td>
                                        <td>
                                            <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                            <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                                <input class="no_spinner" type="number"
                                                  :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedWorkoutBlock.exercises[blockExerciseIndex].load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''"
                                                  :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].load_number"
                                                >{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                            </div>
                                            <div class="flex justify-content-center" v-else>
                                                <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedWorkoutBlock.exercises[blockExerciseIndex])" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="flex justify-content-center">
                                                <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ?(selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes) : '00'">:
                                                <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds) : '00'">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <thead>
                                    <tr>
                                        <th class="set">Sets</th>
                                        <th>Reps</th>
                                        <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                        <th>Load</th>
                                        <th>Rest Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(set, s) in selectedWorkoutBlock.exercises[blockExerciseIndex].set_settings" :key="s">
                                        <template v-if="!set.is_disable">
                                            <td class="set">{{ s + 1 }}</td>
                                            <td>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges || set.rep_unit == 'Max Reps'" type="text" value="max" placeholder="max" disabled >
                                                <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${set.min_reps ? set.min_reps : 0}-${set.max_reps ? set.max_reps : 0}`">
                                                <input v-else type="number" :placeholder="set.reps">
                                            </td>
                                            <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="set.tempo"></td>
                                            <td>
                                                <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                                <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                                    <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="set.load_number">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                                </div>
                                                <div class="flex justify-content-center" v-else>
                                                    <input class="no_spinner" type="number" :placeholder="filterLoadNumber(set)" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex justify-content-center">
                                                    <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (set.rest_minutes < 10 ? `0${set.rest_minutes}` : set.rest_minutes) : '00'">:
                                                    <input class="no_spinner" type="number" min="0" max="59" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (set.rest_seconds < 10 ? `0${set.rest_seconds}` : set.rest_seconds) : '00'">
                                                </div>
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="footer_area">
                        <h4 class="round_details" v-if="selectedWorkoutBlock.perform_type == 2">
                            Round {{ circuitRoundCount }} of {{ selectedWorkoutBlock.circuit_settings.amrap ? 'AMRAP' : `${selectedWorkoutBlock.circuit_settings.round}` }}
                        </h4>
                        <div class="footer_nav" v-if="workoutStarted">
                            <div class="go" @click="handleBackWorkout()"><i class="fas fa-backward"></i></div>
                            <div class="go lg" @click="stopStartRestCountdown(); stopRestFlag = !stopRestFlag;" v-if="!toggleBox && isRestTimer"><i :class="`fas fa-${stoppedRestTimer ?  'play' : 'pause' }`"></i></div>
                            <template v-if="selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].sets > 1"></template>
                            <template v-else>
                                <template v-if="selectedWorkoutBlock.has_work_interval == 0">
                                    <div class="go lg" v-if="selectedWorkoutBlock.perform_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2 && !isRestTimer" :disabled="isRestTimer || (selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM' && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps == '')" @click="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code != '1RM' || oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? handleCompleteExercise(false) : ''"><label>Done</label></div>
                                </template>
                                <template v-else>
                                    <div class="go lg" @click="stopStartWorkCountdown(); stopWorkFlag = !stopWorkFlag;" v-if="workoutStarted && !isRestTimer && (selectedWorkoutBlock.perform_type == 2 || (selectedWorkoutBlock.perform_type == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2)) && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type !== 1"><i :class="`fas fa-${stoppedWorkTimer ?  'play' : 'pause' }`"></i></div>
                                </template>
                            </template>
                            <div class="go lg" @click="stopStartRestCountdown(); stopRestFlag = !stopRestFlag;" v-if="isRestTimer"><i :class="`fas fa-${stoppedRestTimer ?  'play' : 'pause' }`"></i></div>
                            <div class="go lg" v-if="selectedWorkoutBlock.perform_type == 2 && selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 1 && !isRestTimer" :disabled="isRestTimer || (selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM' && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps == '')" @click="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code != '1RM' || oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? handleCompleteExercise(false) : ''"><label>Done</label></div>
                            <div class="go lg" v-if="selectedWorkoutBlock.perform_type == 1 && !isRestTimer" :disabled="isRestTimer || (selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM' && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps == '')" @click="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code != '1RM' || oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? handleCompleteExercise(false) : ''"><label>Done</label></div>
                            <div class="go" @click="handleSkipRest()" v-if="!isExerciseWorkTimer && isRestTimer && (restMinLeft || restSecLeft)"><i class="fas fa-forward"></i></div>
                            <div class="go" @click="handleCompleteExercise(false, true)" v-else ><i class="fas fa-forward"></i></div>
                        </div>
                    </div>
                </div>

                <div class="exercise_list" v-if="navTab == 'complete'">
                    <div class="deatils_header"  v-if="isPlan">
                        <button type="button" class="back_btn" @click="handleCloseCompleteSection()"><i class="fas fa-chevron-left"></i></button>
                    </div>
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">Great work {{ user.full_name }}</h4>
                            <p>Please log your workout and leave any rating, feedback, questions, or concerns for your coach below.</p>
                        </div>
                        <div class="workout_completion">
                            <div class="finish_timer">
                                <h2>Workout Time</h2>
                                <div class="total_timer">
                                    <label><a>{{totalWorkoutHrs < 10 ? `0${totalWorkoutHrs}` : totalWorkoutHrs}}</a><span>hrs</span></label>:
                                    <label><a>{{totalWorkoutMin < 10 ? `0${totalWorkoutMin}` : totalWorkoutMin}}</a><span>min</span></label>:
                                    <label><a>{{totalWorkoutSec < 10 ? `0${totalWorkoutSec}` : totalWorkoutSec}}</a><span>sec</span></label>
                                </div>
                            </div>
                            <div class="ratings">
                                <ul>
                                    <li>
                                        Difficulty
                                        <span><input type="radio" v-model="workoutLogForm.difficulty" name="dif" v-for="n in 5" :value="n" :key="n"/></span>
                                    </li>
                                    <li>
                                        Enjoyment
                                        <span><input type="radio" v-model="workoutLogForm.enjoyment" name="enj" v-for="n in 5" :value="n" :key="n"/></span>
                                    </li>
                                    <li>
                                        Energy
                                        <span><input type="radio" v-model="workoutLogForm.energy" name="enr" v-for="n in 5" :value="n" :key="n"/></span>
                                    </li>
                                    <li>
                                        Mood
                                        <span><input type="radio" v-model="workoutLogForm.mood" name="mod" v-for="n in 5" :value="n" :key="n"/></span>
                                    </li>
                                </ul>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Notes:</label>
                                        <div class="field_wpr">
                                            <textarea name="log_notes" v-model="workoutLogForm.notes" cols="30" rows="10" placeholder="Type your notes here.."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="start_btn" @click="submitWorkoutLog()">Log Workout</button>
                        </div>
                    </div>
                </div>

                <div class="exercise_list" v-if="navTab == 'logged'">
                    <div class="deatils_header" v-if="isPlan">
                        <button type="button" class="back_btn" @click="navTab = 'complete'"><i class="fas fa-chevron-left"></i></button>
                    </div>
                    <div class="exercise_item">
                        <h3 class="user_heading">Awesome {{ user.full_name }}</h3>
                        <div class="workoutLogged" v-if="isPlan">
                            <div class="success"><i class="fas fa-check-circle"></i></div>
                            <h4>Your Workout Has Been Logged!</h4>
                            <p>Next workout is Wednesday April 20th</p>
                            <div class="workout_name">"Lower Body Mania"</div>
                            <div class="reminder">
                                <label for="text_reminder" class="switch_option capsule_btn border-0 m-0">
                                    <h5>Text Reminder?</h5>
                                    <input type="radio" id="text_reminder" :value="1" v-model="reminderType" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="mail_reminder" class="switch_option capsule_btn border-0 m-0">
                                    <h5>Email Reminder?</h5>
                                    <input type="radio" id="mail_reminder" :value="2" v-model="reminderType" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr time_line">
                                <div class="form_grp col-3">
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <input type="number" min="0" max="12" v-model="reminderHr">
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <input type="number" min="0" max="59" v-model="reminderMin">
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <multiselect v-model="reminderClock.value" v-bind="reminderClock"></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reminder_day">
                                <label for="day_before">
                                    <input type="radio" id="day_before" :value="1" v-model="reminderDay" hidden>
                                    <span></span>The day before your workout
                                </label>
                                <label for="very_day">
                                    <input type="radio" id="very_day" :value="2" v-model="reminderDay" hidden>
                                    <span></span>The day of your workout
                                </label>
                            </div>
                            <button type="button" class="start_btn" @click="workoutSave = true; hasRedirect = true;">{{workoutSave ? 'Saved' : 'Save Settings'}}</button>
                        </div>
                        <div class="workoutLogged" v-else>
                            <div class="success"><i class="fas fa-check-circle"></i></div>
                            <h4>Your Workout Has Been Logged!</h4>
                            <p class="text-center py-2">You can see your workout history and chat with your coach in your client portal</p>
                            <button type="button" class="start_btn mt-5" @click="redirectCountdown(0);">Go To Portal</button>
                        </div>
                    </div>
                </div>

                <footer v-if="navTab == 'workout'" class="dashboard_footer" v-show="publicSettings.display_footer && (!exerciseDetail || navTab !== 'details')" :style="`background: ${publicSettings.footer_bgcolor};`">
                    <ul>
                        <li class="pointer" v-if="publicSettings.footer_has_facebook"><a :href="publicSettings.footer_facebook" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="publicSettings.footer_has_twitter"><a :href="publicSettings.footer_twitter" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="publicSettings.footer_has_instagram"><a :href="publicSettings.footer_instagram" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                        <li class="pointer" v-if="publicSettings.footer_has_linkedin"><a :href="publicSettings.footer_linkedin" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                        <li class="pointer" v-if="publicSettings.footer_has_youtube"><a :href="publicSettings.footer_youtube" :style="`border-color: ${publicSettings.footer_textcolor}; color: ${publicSettings.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                    <h4 :style="`color: ${publicSettings.footer_textcolor};`">{{ publicSettings.footer_company ?  publicSettings.footer_company : `© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}` }}</h4>
                    <p>
                        <a href="javascript:void(0);" :style="`color: ${publicSettings.footer_textcolor};`">Terms</a>
                        <a href="javascript:void(0);" :style="`color: ${publicSettings.footer_textcolor};`">Privacy</a>
                        <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                            <a v-if="whiteLabel.has_link == 1" :style="`color: ${publicSettings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                            <a v-else :style="`color: ${publicSettings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                        </template>
                        <a v-else :style="`color: ${publicSettings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                    </p>
                </footer>
            </div>
        </div>

        <!-- Block Details Timer-->
        <div class="start_screen timer lg" :class="{active : startBlockScreen}" v-if="workout && workout.blocks.length">
            <ul class="block_stat">
                <li v-for="(block, b) in selectedWorkoutBlockList" :key="b" :class="{'active' : activeWorkoutBlockIndex > b, 'blink' : activeWorkoutBlockIndex == b}">{{ block.name }}</li>
            </ul>
            <div class="welcome_title">
                <div v-if="activeWorkoutBlockIndex > 0" class="mb-2">
                    <div class="success"><i class="fas fa-check-circle"></i></div>
                    Block {{ activeWorkoutBlockIndex }} is Complete!
                </div>
                <div class="title_sm mb-4">Block {{ activeWorkoutBlockIndex + 1 }} of {{ workout && workout.blocks.length && selectedWorkoutBlockList ? selectedWorkoutBlockList.length : 0 }} is starting in</div>
            </div>
            <div class="rest_alert">
                <div class="score_circle" :class="{pointer : !startBlockMinLeft && !startBlockSecLeft}">
                    <div class="inner_circle">
                        <div class="score" v-if="startBlockMinLeft || startBlockSecLeft">
                            <div>{{startBlockMinLeft < 10 ? `0${startBlockMinLeft}` : startBlockMinLeft}}:{{startBlockSecLeft < 10 ? `0${startBlockSecLeft}` : startBlockSecLeft}}</div>
                        </div>
                        <div class="instant_text" v-else @click="startBlockExercise()"><span>Press</span>Go</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                        <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (totalStartBlockSecLeft * (60 / totalStartBlockSec))" />
                    </svg>
                </div>
            </div>
            <div class="block_note" v-if="selectedWorkoutBlock.perform_type == 2">
                {{ getWorkoutBlockDetail(selectedWorkoutBlock) }}
            </div>
            <div class="block_note" v-if="selectedWorkoutBlock.perform_type == 1">
                <!-- Complete all sets of each exercise before moving to the next exercise. -->
                This is a {{ selectedWorkoutBlock.exercises.length }}-exercise progression
            </div>
            <div class="final_note" v-if="(activeWorkoutBlockIndex + 1) == selectedWorkoutBlockList.length">
                After Completion of this block your workout is over and you can give feedback to your coach.
            </div>
            <div class="footer_nav">
                <div class="go" @click="handleBackWorkout()"><i class="fas fa-backward"></i></div>
                <div class="go lg" v-if="startBlockMinLeft || startBlockSecLeft" @click="stopStartCountdown">
                    <i :class="`fas fa-${stoppedBlockScreenTimer ?  'play' : 'pause' }`"></i>
                </div>
                <div class="go lg ripple" v-else @click="startBlockExercise()">Go</div>
                <div class="go" @click="skipStartScreen()"><i class="fas fa-forward"></i></div>
            </div>
        </div>

        <div class="timer" :class="{active : workoutStarted, lg : toggleBox}" style="display:none;">
            <button type="button" class="toggle_box" @click="toggleBox = !toggleBox;"><i class="fas fa-bars"></i></button>
            <div class="alert" v-if="!isRestTimer && !toggleBox && oneRepMaxCalculations[activeWorkoutBlockIndex] && !oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps && selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length &&  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'" @click="repConfirm = !repConfirm; isRestTimer = false;">
                Please calculate your 1 rep max. <i class="fas fa-long-arrow-alt-right"></i>&nbsp;&nbsp;Calculate
            </div>

           <!-- Current Workout details -->
            <Transition duration="550" name="nested">
                <div class="exercise_details" v-if="toggleBox">
                    <div class="instruction pt-2" v-if="selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length && (selectedWorkoutBlock.exercises.length - 1 > blockExerciseIndex)">
                        <h3 class="label_heading" v-if="isRestTimer">Up Next...</h3>
                        <h4 class="headline">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].name}}</h4>
                        <div class="video_wpr exercise" :class="{ video_wpr_flip: selectedWorkoutBlock.exercises[blockExerciseIndex].is_flip}" v-if="!isRestTimer">
                            <div class="video-overlay"></div>
                            <div v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].has_gif == 1 && selectedWorkoutBlock.exercises[blockExerciseIndex] && selectedWorkoutBlock.exercises[blockExerciseIndex].gif_image" class="player_wpr">
                                <img :src="selectedWorkoutBlock.exercises[blockExerciseIndex].gif_image" :alt="selectedWorkoutBlock.exercises[blockExerciseIndex].name">
                            </div>
                            <div v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedWorkoutBlock.exercises[blockExerciseIndex].video_link)"></div>
                            <div v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].video_type == 2" class="player_wpr" v-html="selectedWorkoutBlock.exercises[blockExerciseIndex].embed_code"></div>
                        </div>
                    </div>
                    <div class="instruction pt-2" v-else-if="selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length">
                        <h3 class="label_heading" v-if="isRestTimer">Up Next...</h3>
                        <h4 class="headline">{{ selectedWorkoutBlockExercise.name}}</h4>
                        <div class="video_wpr exercise" :class="{ video_wpr_flip: selectedWorkoutBlockExercise.is_flip}" v-if="!isRestTimer">
                            <div class="video-overlay"></div>
                            <div v-if="selectedWorkoutBlockExercise.has_gif == 1 && selectedWorkoutBlockExercise.gif_image" class="player_wpr">
                                <img :src="selectedWorkoutBlockExercise.gif_image" :alt="selectedWorkoutBlockExercise.name">
                            </div>
                            <div v-else-if="selectedWorkoutBlockExercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedWorkoutBlockExercise.video_link)"></div>
                            <div v-else-if="selectedWorkoutBlockExercise.video_type == 2" class="player_wpr" v-html="selectedWorkoutBlockExercise.embed_code"></div>
                        </div>
                    </div>
                </div>
            </Transition>
            <div class="other_counters">
                <div class="counter" v-if="!toggleBox && isRestTimer">
                    <h6>Rest!</h6>
                    <span>{{restMinLeft < 10 ? `0${restMinLeft}` : restMinLeft}}</span> : <span>{{restSecLeft < 10 ? `0${restSecLeft}` : restSecLeft}}</span>
                    <button class="play_pause_btn" @click="stopStartRestCountdown"><i :class="`fas fa-${stoppedRestTimer ?  'play' : 'pause' }`"></i></button>
                </div>
                <div class="counter" v-if="selectedWorkoutBlock.perform_type == 2 && !toggleBox && isExerciseWorkTimer && !isRestTimer">
                    <h6>Work!</h6>
                    <span>{{workMinLeft < 10 ? `0${workMinLeft}` : workMinLeft}}</span> : <span>{{workSecLeft < 10 ? `0${workSecLeft}` : workSecLeft}}</span>
                    <button class="play_pause_btn" @click="stopStartWorkCountdown"><i :class="`fas fa-${stoppedWorkTimer ?  'play' : 'pause' }`"></i></button>
                </div>
            </div>
            <Transition duration="550" name="nested">
                <div>
                    <div class="rest_alert" v-if="toggleBox && isRestTimer">
                        <h5>Starting in..</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <img v-if="selectedWorkoutBlock.exercises[blockExerciseIndex]" :src="selectedWorkoutBlock.exercises[blockExerciseIndex].gif_image" :alt="selectedWorkoutBlock.exercises[blockExerciseIndex].name">
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                                <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (totalRestSecLeft * (60 / totalRestSec))"/>
                            </svg>
                        </div>
                    </div>
                    <div class="counting_timer" v-if="toggleBox && isRestTimer">
                        {{restMinLeft < 10 ? `0${restMinLeft}` : restMinLeft}}:{{restSecLeft < 10 ? `0${restSecLeft}` : restSecLeft}}
                    </div>
                    <div class="rest_alert" v-if="toggleBox && workoutStarted && !isRestTimer && selectedWorkoutBlock.perform_type == 2">
                       <h5>Work!</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <div class="score">
                                    <div>{{workMinLeft < 10 ? `0${workMinLeft}` : workMinLeft}}:{{workSecLeft < 10 ? `0${workSecLeft}` : workSecLeft}}</div>
                                    <button class="play_pause_btn" @click="stopStartWorkCountdown"><i :class="`fas fa-${stoppedWorkTimer ?  'play' : 'pause' }`"></i></button>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                                <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (totalWorkSecLeft * (60 / totalWorkSec))"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition duration="550" name="nested">
                <div class="rounds" v-if="selectedWorkoutBlock.perform_type == 2 && toggleBox">
                    <span>Round</span>
                    <ul>
                        <li class="active">{{ circuitRoundCount }}</li>
                    </ul>
                </div>
            </Transition>
            <div class="footer_nav">
                <div class="go" @click="handleBackWorkout()"><i class="fas fa-backward"></i></div>
                <div class="go" @click="stopStartRestCountdown" v-if="isRestTimer"><i :class="`fas fa-${stoppedRestTimer ?  'play' : 'pause' }`"></i></div>
                <div class="go" @click="handleSkipRest()" v-if="!isExerciseWorkTimer && isRestTimer && (restMinLeft || restSecLeft)"><i class="fas fa-forward"></i></div>
                <div class="go" @click="handleCompleteExercise(false, true)" v-else ><i class="fas fa-forward"></i></div>
            </div>
        </div>

        <div class="timer task_details" :class="{active : skipExerciseMoreDetails}" v-if="navTab == 'details' && selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length">
            <div class="cross_btn" @click.stop="skipExerciseMoreDetails = false;"><i class="fas fa-chevron-down"></i></div>
            <div class="sticky_bar">
                <div class="total_counter">
                    <div><span>{{totalWorkoutHrs < 10 ? `0${totalWorkoutHrs}` : totalWorkoutHrs}}</span> : <span>{{totalWorkoutMin < 10 ? `0${totalWorkoutMin}` : totalWorkoutMin}}</span> : <span>{{totalWorkoutSec < 10 ? `0${totalWorkoutSec}` : totalWorkoutSec}}</span></div>
                </div>
                <button type="button" class="skip_exercise_btn" @click="skipExerciseBlock = true;">Skip To Exercise</button>
            </div>
            <h4 class="headline p-0">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].name }}</h4>
            <h5 v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].description">{{  selectedWorkoutBlock.exercises[blockExerciseIndex].description }}</h5>
            <div class="instruction mt-4" v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].instructions">
                <h4 class="headline">Instructions</h4>
                <p>{{ selectedWorkoutBlock.exercises[blockExerciseIndex].instructions ? selectedWorkoutBlock.exercises[blockExerciseIndex].instructions : '' }}</p>
            </div>
            <div class="activity mt-4 mb-1" ref="calc_section">
                <div v-if="selectedWorkoutBlock.perform_type == 2">
                    <table>
                        <thead>
                            <tr>
                                <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">Work Time</th>
                                <th v-else>Reps</th>
                                <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                <th>Load</th>
                                <th>Rest Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">
                                    <input class="no_spinner" type="number" min="0" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes">:
                                    <input class="no_spinner" type="number" min="0" max="59" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds">
                                </td>
                                <td v-else>
                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                    <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps : 0}-${selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps : 0}`">
                                    <input v-else type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_reps">
                                </td>
                                <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].tempo"></td>
                                <td>
                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                    <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                        <input class="no_spinner" type="number"
                                            :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedWorkoutBlock.exercises[blockExerciseIndex].load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''"
                                            :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].load_number"
                                        >{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit : '% ' + selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                    </div>
                                    <div class="flex justify-content-center" v-else>
                                        <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedWorkoutBlock.exercises[blockExerciseIndex])" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="flex justify-content-center">
                                        <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes) : '00'">:
                                        <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds) : '00'">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <table v-if="!selectedWorkoutBlock.exercises[blockExerciseIndex].is_variable">
                        <thead>
                            <tr>
                                <th class="set">Sets</th>
                                <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">Work Time</th>
                                <th v-else>Reps</th>
                                <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                <th>Load</th>
                                <th>Rest Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="set">{{ selectedWorkoutBlock.exercises[blockExerciseIndex].sets }}</td>
                                <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].measure_type == 2">
                                    <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_minutes" disabled>:
                                    <input class="no_spinner" type="number" :placeholder="(selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds < 10 ? '0' : '') + selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_seconds" disabled>
                                </td>
                                <td v-else>
                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                    <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps ? selectedWorkoutBlock.exercises[blockExerciseIndex].min_reps : ''} - ${selectedWorkoutBlock.exercises[blockExerciseIndex].max_reps}`">
                                    <input v-else type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].exercise_reps">
                                </td>
                                <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].tempo"></td>
                                <td>
                                    <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                    <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                        <input class="no_spinner" type="number"
                                            :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedWorkoutBlock.exercises[blockExerciseIndex].load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''"
                                            :placeholder="selectedWorkoutBlock.exercises[blockExerciseIndex].load_number"
                                        >{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                    </div>
                                    <div class="flex justify-content-center" v-else>
                                        <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedWorkoutBlock.exercises[blockExerciseIndex])" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="flex justify-content-center">
                                        <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_minutes) : '00'">:
                                        <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds < 10 ? `0${selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds}` : selectedWorkoutBlock.exercises[blockExerciseIndex].rest_seconds) : '00'">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-else>
                        <thead>
                            <tr>
                                <th class="set">Sets</th>
                                <th>Reps</th>
                                <th v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo">Tempo</th>
                                <th>Load</th>
                                <th>Rest Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(set, s) in selectedWorkoutBlock.exercises[blockExerciseIndex].set_settings" :key="s">
                                <template v-if="!set.is_disable">
                                    <td class="set">{{ s + 1 }}</td>
                                    <td>
                                        <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_failure && !selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges || set.rep_unit == 'Max Reps'" type="text" value="max" placeholder="max" disabled >
                                        <input v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_reps_ranges" type="number" :placeholder="`${set.min_reps ? set.min_reps : 0}-${set.max_reps ? set.max_reps : 0}`">
                                        <input v-else type="number" :placeholder="set.reps">
                                    </td>
                                    <td v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].is_tempo"><input type="number" :placeholder="set.tempo"></td>
                                    <td>
                                        <input v-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                        <div class="flex justify-content-center" v-else-if="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM'">
                                            <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="set.load_number">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' +  selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code.toLowerCase() }}
                                        </div>
                                        <div class="flex justify-content-center" v-else>
                                            <input class="no_spinner" type="number" :placeholder="filterLoadNumber(set)" disabled> {{ filterLoadUnitCode(selectedWorkoutBlock.exercises[blockExerciseIndex]) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-content-center">
                                            <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? (set.rest_minutes < 10 ? `0${set.rest_minutes}` : set.rest_minutes) : '00'">:
                                            <input class="no_spinner" type="number" min="0" max="59" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (set.rest_seconds < 10 ? `0${set.rest_seconds}` : set.rest_seconds) : '00'">
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="action_wpr">
                <button type="button" :disabled="isRestTimer" class="incomplete_btn" @click="incomplete = true;">Mark Incomplete</button>
                <button type="button" :disabled="isRestTimer || (selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code == '1RM' && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps == '')" class="complete_btn" @click="selectedWorkoutBlock.exercises[blockExerciseIndex].load_unit_code != '1RM' || oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? handleCompleteExercise(false) : ''">
                    <i class="fa fa-spinner fa-spin" v-if="exerciseLoader && !incomplete"></i> {{ exerciseLoader && !incomplete ? 'Saving' : 'Done' }}
                </button>
                <div class="incomplete_sec" v-if="incomplete">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label mb-2">Reason for non-completion</label>
                                <div class="field_wpr">
                                    <textarea id="" cols="30" rows="10" placeholder="Type your reason here.."></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="cancel_rsn" @click="incomplete = false;">Cancel</button>
                        <button type="button" class="save_rsn" @click="handleCompleteExercise()"><i class="fa fa-spinner fa-spin" v-if="exerciseLoader && incomplete"></i> {{ exerciseLoader && incomplete ? 'Saving' : 'Save' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="timer task_details" :class="{active : skipExerciseBlock}" v-if="navTab == 'details' && selectedWorkoutBlock.exercises && selectedWorkoutBlock.exercises.length">
            <div class="cross_btn" @click.stop="skipExerciseBlock = false; skipExerciseMoreDetails = false;"><i class="fas fa-chevron-down"></i></div>
            <ul class="block_list">
                <li v-for="(block, b) in selectedWorkoutBlockList" :key="b">
                    <div class="block_item" :class="{'show' : b == 0}">
                        <h4 @click="accordion($event)">
                            <img src="@/assets/images/kettlebell.svg">
                            <div class="title">
                                {{block.name}}
                                <span class="block_type">Perform as a {{block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span>
                                <div class="plan_info" v-if="block.perform_type == 2">
                                    {{block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round} rounds`}}
                                    <label v-if="block.circuit_settings.work_min || block.circuit_settings.work_sec">&nbsp;| {{block.circuit_settings.work_min ? `${block.circuit_settings.work_min} min` : ''}} {{block.circuit_settings.work_sec ? `${block.circuit_settings.work_sec} sec` : ''}} work </label>
                                    <label v-if="block.circuit_settings.rest_min || block.circuit_settings.rest_sec">&nbsp;| {{block.circuit_settings.rest_min ? `${block.circuit_settings.rest_min} min` : ''}} {{block.circuit_settings.rest_sec ? `${block.circuit_settings.rest_sec} sec` : ''}} rest</label>
                                    <label v-if="block.circuit_settings.amrap && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec)">&nbsp;| {{ block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min} min` : ''  }} {{ block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec} sec` : '' }} duration</label>
                                </div>
                            </div>
                            <i class="fas fa-chevron-down"></i>
                        </h4>
                        <div class="exercise_items">
                            <div class="item" v-for="(exercise, e) in block.exercises" :key="e">
                                <div class="video_area">
                                    <div class="video_wpr exercise" :class="{ video_wpr_flip: exercise.is_flip}">
                                        <img :src="exercise.thumb" :alt="exercise.name">
                                    </div>
                                </div>
                                <div class="info_wpr">
                                    <h4>{{exercise.name}}</h4>
                                    <h6>
                                        <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                        <span v-if="block.perform_type != 2">{{ exercise.sets }} sets </span>
                                        <span v-if="exercise.measure_type != 2">&nbsp;{{ exercise.is_failure && !exercise.is_reps_ranges ? 'max' : exercise.exercise_reps }} reps </span>
                                        <span v-if="exercise.measure_type == 2 && exercise.exercise_minutes">&nbsp;{{ exercise.exercise_minutes }} min</span>
                                        <span v-if="exercise.measure_type == 2 && exercise.exercise_seconds">&nbsp;{{ exercise.exercise_seconds }} sec </span>
                                        <span v-if="exercise.load_unit_code == '1RM'">
                                            &nbsp;{{ oneRepMaxCalculations[b] && oneRepMaxCalculations[b][e] && oneRepMaxCalculations[b][e].reps ? Math.round(exercise.load_number * 0.01 * parseInt(oneRepMaxCalculations[b][e].reps)) + ' ' + oneRepMaxCalculations[b][e].weight_unit : `${exercise.load_number}% of 1RM` }}
                                        </span>
                                        <span v-else-if="exercise.load_unit_code == 'BW'">&nbsp;bodyweight</span>
                                        <span v-else>&nbsp;{{ filterLoadNumber(exercise) }} {{ filterLoadUnitCode(exercise) }} </span>
                                    </h6>
                                    <button type="button" @click="handleStartExercise(block, b, exercise, e)" class="start_exercise_btn">Start Exercise <i class="fas fa-long-arrow-alt-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

         <!-- Exercise Details -->
         <div class="card_details active" v-if="exerciseDetail">
            <div class="deatils_header">
                <button type="button" class="back_btn" @click="exerciseDetail = false; repConfirm = false;">
                    <i class="fas fa-chevron-left"></i>Workout
                </button>
            </div>
            <div class="exercise_details">
                <div class="instruction pt-0">
                    <h4 class="headline">{{ selectedDetailExercise.name }}</h4>
                    <h5>{{ selectedDetailExercise.description }}</h5>
                    <div class="video_wpr exercise" :class="{ video_wpr_flip: selectedDetailExercise.is_flip}">
                        <img :src="selectedDetailExercise.gif_image" :alt="selectedDetailExercise.name">
                    </div>
                </div>
                <div class="instruction" v-if="selectedDetailExercise.instructions">
                    <h4 class="headline">Instructions</h4>
                    <p>{{ selectedDetailExercise.instructions ? selectedDetailExercise.instructions : '' }}</p>
                </div>
                <div class="activity">
                    <div class="instruction pt-0">
                        <h4 class="headline">
                            Exercise Details
                            <button type="button" class="calc_btn" :class="!oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? 'blink' : ''" v-if="selectedDetailExercise.load_unit_code == '1RM'" @click="repConfirm = !repConfirm; isRestTimer = false;">{{repConfirm ? 'Edit 1RM' : 'Calculate 1RM'}}</button>
                        </h4>
                    </div>
                    <div  v-if="selectedDetailBlock.perform_type == 2">
                        <table>
                            <thead>
                                <tr>
                                    <th v-if="selectedDetailExercise.measure_type == 2">Work Time</th>
                                    <th v-else>Reps</th>
                                    <th v-if="selectedDetailExercise.is_tempo">Tempo</th>
                                    <th>Load</th>
                                    <th>Rest Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="selectedDetailExercise.measure_type == 2">
                                        <input class="no_spinner" type="number" :placeholder="(selectedDetailExercise.exercise_minutes < 10 ? '0' : '') + selectedDetailExercise.exercise_minutes" disabled>:
                                        <input class="no_spinner" type="number" :placeholder="(selectedDetailExercise.exercise_seconds < 10 ? '0' : '') + selectedDetailExercise.exercise_seconds" disabled>
                                    </td>
                                    <td v-else>
                                        <input v-if="selectedDetailExercise.is_failure && !selectedDetailExercise.is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                        <input v-else-if="selectedDetailExercise.is_reps_ranges" type="number" :placeholder="`${selectedDetailExercise.min_reps ? selectedDetailExercise.min_reps : 0}-${selectedDetailExercise.max_reps ? selectedDetailExercise.max_reps : 0}`">
                                        <input v-else type="number" :placeholder="selectedDetailExercise.exercise_reps">
                                    </td>
                                    <td v-if="selectedDetailExercise.is_tempo"><input type="number" :placeholder="selectedDetailExercise.tempo"></td>
                                    <td>
                                        <input v-if="selectedDetailExercise.load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                        <div class="flex justify-content-center" v-else-if="selectedDetailExercise.load_unit_code == '1RM'">
                                            <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedDetailExercise.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="selectedDetailExercise.load_number">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' +  selectedDetailExercise.load_unit_code.toLowerCase() }}
                                        </div>
                                        <div class="flex justify-content-center" v-else>
                                            <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedDetailExercise)" disabled> {{ filterLoadUnitCode(selectedDetailExercise) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-content-center">
                                            <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? ( selectedDetailExercise.rest_minutes < 10 ? `0${selectedDetailExercise.rest_minutes}` : selectedDetailExercise.rest_minutes) : '00'">:
                                            <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? ( selectedDetailExercise.rest_seconds < 10 ? `0${selectedDetailExercise.rest_seconds}` : selectedDetailExercise.rest_seconds) : '00'">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <table v-if="!selectedDetailExercise.is_variable">
                            <thead>
                                <tr>
                                    <th class="set">Set</th>
                                    <th v-if="selectedDetailExercise.measure_type == 2">Work Time</th>
                                    <th v-else>Reps</th>
                                    <th v-if="selectedDetailExercise.is_tempo">Tempo</th>
                                    <th>Load</th>
                                    <th>Rest Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="set">{{ selectedDetailExercise.sets }}</td>
                                    <td v-if="selectedDetailExercise.measure_type == 2">
                                        <input class="no_spinner" type="number" :placeholder="(selectedDetailExercise.exercise_minutes < 10 ? '0' : '') + selectedDetailExercise.exercise_minutes" disabled>:
                                        <input class="no_spinner" type="number" :placeholder="(selectedDetailExercise.exercise_seconds < 10 ? '0' : '') + selectedDetailExercise.exercise_seconds" disabled>
                                    </td>
                                    <td v-else>
                                        <input v-if="selectedDetailExercise.is_failure && !selectedDetailExercise.is_reps_ranges" type="text" value="max" placeholder="max" disabled>
                                        <input v-else-if="selectedDetailExercise.is_reps_ranges" type="number" :placeholder="`${selectedDetailExercise.min_reps ? selectedDetailExercise.min_reps : 0}-${selectedDetailExercise.max_reps ? selectedDetailExercise.max_reps : 0}`">
                                        <input v-else type="number" :placeholder="selectedDetailExercise.exercise_reps">
                                    </td>
                                    <td v-if="selectedDetailExercise.is_tempo">{{ selectedDetailExercise.tempo }}</td>
                                    <td>
                                        <input v-if="selectedDetailExercise.load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                        <div class="flex justify-content-center" v-else-if="selectedDetailExercise.load_unit_code == '1RM'">
                                            <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(selectedDetailExercise.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="selectedDetailExercise.load_number">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' +  selectedDetailExercise.load_unit_code.toLowerCase() }}
                                        </div>
                                        <div class="flex justify-content-center" v-else>
                                            <input class="no_spinner" type="number" :placeholder="filterLoadNumber(selectedDetailExercise)" disabled> {{ filterLoadUnitCode(selectedDetailExercise) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-content-center">
                                            <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? ( selectedDetailExercise.rest_minutes < 10 ? `0${selectedDetailExercise.rest_minutes}` : selectedDetailExercise.rest_minutes) : '00'">:
                                            <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="selectedDetailExercise.is_rest ? ( selectedDetailExercise.rest_seconds < 10 ? `0${selectedDetailExercise.rest_seconds}` : selectedDetailExercise.rest_seconds) : '00'">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-else>
                            <thead>
                                <tr>
                                    <th class="set">Sets</th>
                                    <th>Reps</th>
                                    <th v-if="selectedDetailExercise.is_tempo">Tempo</th>
                                    <th>Load</th>
                                    <th>Rest Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(set, s) in selectedDetailExercise.set_settings" :key="s">
                                    <template v-if="!set.is_disable">
                                        <td class="set">{{ s + 1 }}</td>
                                        <td>
                                            <input v-if="selectedDetailExercise.is_failure && !selectedDetailExercise.is_reps_ranges || set.rep_unit == 'Max Reps'" type="text" value="max" placeholder="max" disabled >
                                            <input v-else-if="selectedDetailExercise.is_reps_ranges" type="number" :placeholder="`${set.min_reps ? set.min_reps : 0}-${set.max_reps ? set.max_reps : 0}`">
                                            <input v-else type="number" :placeholder="set.reps">
                                        </td>
                                        <td v-if="selectedDetailExercise.is_tempo">{{ set.tempo }}</td>
                                        <td>
                                            <input v-if="selectedDetailExercise.load_unit == 'Bodyweight'" :placeholder="'Bodyweight'" disabled>
                                            <div class="flex justify-content-center" v-else-if="selectedDetailExercise.load_unit_code == '1RM'">
                                                <input class="no_spinner" type="number" :value="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps)) : ''" :placeholder="set.load_number">{{ oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? weightUnit.value : '% ' + selectedDetailExercise.load_unit_code.toLowerCase() }}
                                            </div>
                                            <div class="flex justify-content-center" v-else>
                                                <input class="no_spinner" type="number" :placeholder="filterLoadNumber(set)" disabled>{{ filterLoadUnitCode(selectedDetailExercise) }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="flex justify-content-center">
                                                <input class="no_spinner" type="number" min="0" @keyup="enforceMinMax" :placeholder=" selectedDetailExercise.is_rest ? (set.rest_minutes < 10 ? `0${set.rest_minutes}` : set.rest_minutes) : '00'">:
                                                <input class="no_spinner" type="number" min="0" max="60" @keyup="enforceMinMax" :placeholder="  selectedDetailExercise.is_rest ? (set.rest_seconds < 10 ? `0${set.rest_seconds}` : set.rest_seconds) : '00'">
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Redirect Page -->
        <div class="start_screen timer active lg" v-if="hasRedirect">
            <div class="welcome_title">
                <div class="mb-3">
                    <div class="success"><i class="fas fa-check-circle"></i></div>
                    You Have Completed The Workout Sucessfully!
                </div>
                <div class="title_sm mb-4">Yor will be redirected back to plan in</div>
            </div>
            <div class="rest_alert">
                <div class="score_circle">
                    <div class="inner_circle">
                        <div class="score">
                            <div>{{ redirectSecLeft }}</div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                        <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (redirectSecLeft * 20)"/>
                    </svg>
                </div>
            </div>
        </div>

        <!-- Rep calculate -->
        <div class="rep_confirmation" :class="{active : repConfirm && !isRestTimer}" v-if="oneRepMaxCalculations[activeWorkoutBlockIndex]">
            <div class="setting_wpr">
                <h3 class="flex-space-between">What is your 1 rep max for this exercise? <i class="fa fa-volume-up pointer" @click="playOneRepMaxAudio()"></i></h3>
                <div class="form_grp">
                    <div class="group_item">
                        <div class="field_wpr">
                            <input type="number" v-model="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps" @focus.stop="oneRepMaxCalculations[activeWorkoutBlockIndex] && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex] && oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps ? oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps = oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps : oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].reps = 80" placeholder="80">
                        </div>
                    </div>
                    <div class="group_item">
                        <div class="field_wpr">
                            <multiselect v-model="oneRepMaxCalculations[activeWorkoutBlockIndex][blockExerciseIndex].weight_unit" v-bind="weightUnit"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="primary_btn" @click="repCalculate">Calculate Loads</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import SwiperCore, { Navigation, Pagination } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation, Pagination])

    import moment from 'moment'
    import VideoParser from '@/utils/VideoParser'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    const firstBlock = new Audio(require('@/assets/audio/workout/block/first-block.mp3'))
    const nextBlock = new Audio(require('@/assets/audio/workout/block/next-block.mp3'))
    const finalBlock = new Audio(require('@/assets/audio/workout/block/final-block.mp3'))
    const blockComplete = new Audio(require('@/assets/audio/workout/block/block-complete.mp3'))
    const fiveSecLeft = new Audio(require('@/assets/audio/workout/exercise/5-seconds-left.mp3'))
    const tenSecLeft = new Audio(require('@/assets/audio/workout/exercise/10-seconds-left.mp3'))
    const twentySecLeft = new Audio(require('@/assets/audio/workout/exercise/20-seconds-left.mp3'))
    const fiveSecLeftWork = new Audio(require('@/assets/audio/workout/exercise/5-seconds-left-work.mp3'))
    const tenSecLeftWork = new Audio(require('@/assets/audio/workout/exercise/10-seconds-left-work.mp3'))
    const twentySecLeftWork = new Audio(require('@/assets/audio/workout/exercise/20-seconds-left-work.mp3'))
    const firstExercise = new Audio(require('@/assets/audio/workout/exercise/first-exercise.mp3'))
    const lastExercise = new Audio(require('@/assets/audio/workout/exercise/last-exercise.mp3'))
    const nextExercise = new Audio(require('@/assets/audio/workout/exercise/next-exercise.mp3'))
    const startBlock = new Audio(require('@/assets/audio/workout/exercise/start.mp3'))
    const restExercise = new Audio(require('@/assets/audio/workout/exercise/rest.mp3'))
    const workoutComplete = new Audio(require('@/assets/audio/workout/finish/complete.mp3'))
    const workoutLogged = new Audio(require('@/assets/audio/workout/finish/logged.mp3'))
    const rateWorkout = new Audio(require('@/assets/audio/workout/finish/rate-workout.mp3'))
    const roundComplete = new Audio(require('@/assets/audio/workout/round/complete.mp3'))
    const finalRound = new Audio(require('@/assets/audio/workout/round/final.mp3'))
    const firstRound = new Audio(require('@/assets/audio/workout/round/first.mp3'))
    const nextRound = new Audio(require('@/assets/audio/workout/round/next.mp3'))
    const oneRepMaxAudio = new Audio(require('@/assets/audio/workout/one-rep-max-info-blurb.mp3'))

    export default {
        name: 'Plan Workout Preview',

        data () {
            return {
                moment,
                startBlockScreen: false,
                workoutStarted: false,
                totalWorkoutMin: 0,
                totalWorkoutSec: 0,
                totalWorkoutHrs: 0,
                totalInterval: null,
                startBlockMinLeft: 0,
                startBlockSecLeft: 15,
                startBlockInterval: null,
                stoppedBlockScreenTimer: false,
                restMinLeft: 0,
                restSecLeft: 0,
                totalRestSec: 30,
                totalRestSecLeft: 30,
                restInterval: null,
                stoppedRestTimer:false,
                workMinLeft: 0,
                workSecLeft: 30,
                workInterval: null,
                stoppedWorkTimer:false,
                navTab: 'workout',
                hasNextBtn: false,
                toggleBox: false,
                isRestTimer: false,
                restPeriod: 1,
                incomplete: false,
                reminderType: 1,
                reminderDay: 1,
                reminderHr: 7,
                reminderMin: 30,
                reminderClock: {
                    mode: 'single',
                    value: ['AM'],
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'PM', label: 'PM' }
                    ],
                },
                workoutSave: false,
                weightUnit: {
                    mode: 'single',
                    value: 'lbs',
                    options: [
                        { label: 'Pounds', value: 'lbs' },
                        { label: 'Kilograms', value: 'kgs' },
                        { label: 'Stones', value: 'stones' }
                    ],
                },
                repConfirm: false,
                selectedWorkout: {blocks: []},
                selectedWorkoutBlock: {exercises: []},
                activeBlockExercise: {},
                selectedWorkoutBlockList: [],
                selectedBlockExerciseList: [],
                activeWorkoutBlockIndex: 0,
                blockExerciseIndex: 0,
                selectedWorkoutBlockExercise: {},
                isExerciseWorkTimer: true,
                exerciseDetail: false,
                selectedDetailExercise: {},
                selectedDetailBlock: {},
                workoutLogForm: {
                    difficulty: 0,
                    enjoyment: 0,
                    energy: 0,
                    mood: 0,
                    notes: '',
                },
                hasRedirect: false,
                redirectSecLeft: 0,
                circuitRoundCount: 1,
                selectedExerciseSetsCount: 1,
                supersetExercisesIndex: null,
                supersetRoundCount: 1,
                supersetExercisesRound: 0,
                supersetStarted: false,
                exerciseLoader: false,
                workoutStats: {
                    sets: 0,
                    rounds: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                },
                whiteLabel: '',
                totalWorkSecLeft: 30,
                totalWorkSec: 30,
                skipAudioPlay: false,
                device: 'cell',
                oneRepMaxCalculations: [],
                savedWorkout: {
                    blockId: 0,
                    exerciseId: 0,
                    blockRound: 0,
                    tab: 'workout',
                    totalWorkoutMin: 0,
                    totalWorkoutSec: 0,
                    totalWorkoutHrs: 0,
                },
                isResumeWorkout: 0,
                isBackWorkout: false,
                skipExerciseMoreDetails: false,
                stopRestFlag: false,
                stopWorkFlag: false,
                skipExerciseBlock: false,
                workCoundownTimerStarted: false,
                restCoundownTimerStarted: false,
                totalStartBlockSecLeft: 15,
                totalStartBlockSec: 15,
                playingNextStepAudio: false,
            };
        },

        props: {
            workout: Object,
            publicSettings: Object,
            isPlan: {
                type: Boolean,
                default: true,
            }
        },

        components:{
            Swiper,
            SwiperSlide,
        },

        watch: {
            workout: {
                handler (workout) {
                    const vm = this;

                    vm.selectedWorkout          = JSON.parse(JSON.stringify(workout));
                    vm.selectedWorkoutBlockList = vm.selectedWorkout && vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length ? JSON.parse(JSON.stringify(vm.selectedWorkout.blocks)) : [];

                    vm.handleWorkoutStats();
                },
                deep: true
            },

            startBlockScreen (val) {
                const vm          = this;
                let scrollContent = document.querySelector('.workout_scroll');

                if (scrollContent) {
                    scrollContent.scrollTo(0, 0);
                }

                if (val) {
                    if (vm.selectedWorkoutBlock && vm.selectedWorkoutBlock.has_block_screen) {
                        vm.startBlockMinLeft        = parseInt(vm.selectedWorkoutBlock.block_interval_min);
                        vm.startBlockSecLeft        = parseInt(vm.selectedWorkoutBlock.block_interval_sec);
                        vm.stoppedScreenTime        = true;
                        vm.totalStartBlockSecLeft   = vm.startBlockMinLeft ? (vm.startBlockMinLeft * 60) + vm.startBlockSecLeft : vm.startBlockSecLeft;
                        vm.totalStartBlockSec       = vm.totalStartBlockSecLeft;

                        vm.startBlockCountdown();
                    } else {
                        vm.startBlockMinLeft = 0;
                        vm.startBlockSecLeft = 0;
                        vm.startBlockExercise();
                        vm.startBlockScreen = false;

                        setTimeout(() => {
                            if (vm.selectedWorkoutBlockList.length > 1 && vm.activeWorkoutBlockIndex && vm.blockExerciseIndex == 0 && vm.supersetStarted) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            }
                        });
                    }

                    if (scrollContent) {
                        scrollContent.style.overflow = 'hidden';
                    }
                } else {
                    if (scrollContent) {
                        scrollContent.style.overflow = 'scroll';
                    }
                }
            },

            exerciseDetail (val) {
                let scroll_content = document.querySelector('.workout_scroll');

                if (scroll_content) {
                    scroll_content.scrollTo(0, 0);

                    if (val) {
                        setTimeout(function() {
                            scroll_content.style.overflow = 'hidden';
                        }, 500);
                    } else {
                        scroll_content.style.overflow = 'scroll';
                    }
                }
            },

            hasRedirect(val){
                const vm = this;

                if (val) {
                    vm.redirectCountdown(3);
                }
            },

            repConfirm (val) {
                const vm = this;

                let el = document.querySelector('.workout_scroll');

                if (el) {
                    if (val) {
                        el.scrollTo(0,0);

                        el.style.overflowY = 'hidden';
                    } else {
                        el.style.overflowY = 'scroll';
                    }
                }
            },

            totalStartBlockSecLeft (val) {
                const vm = this;

                if (vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_block_voice && !vm.skipAudioPlay) {
                    if (vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_block_10_sec_voice) {
                        if (vm.activeWorkoutBlockIndex == 0 && val === 10) {
                            vm.handlePlayAudioPrompt('firstBlock');
                        } else if(vm.activeWorkoutBlockIndex > 0 && vm.activeWorkoutBlockIndex < vm.selectedWorkoutBlockList.length - 1 && val === 10) {
                            vm.handlePlayAudioPrompt('nextBlock');
                        } else if(vm.activeWorkoutBlockIndex == vm.selectedWorkoutBlockList.length - 1 && val === 10) {
                            vm.handlePlayAudioPrompt('finalBlock');
                        }
                    }

                    if (val == 0 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_block_start_voice) {
                        vm.handlePlayAudioPrompt('startBlock');
                    }
                }
            },

            activeWorkoutBlockIndex (val) {
                const vm = this;

                if (val && vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length && vm.activeWorkoutBlockIndex > 0 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1].is_block_voice && !vm.skipAudioPlay && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_block_complete_voice && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_block_screen) {
                    vm.handlePlayAudioPrompt('blockComplete');
                }
            },

            restSecLeft (val) {
                const vm = this;

                if (vm.restMinLeft == 0 && vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_rest_voice && !vm.skipAudioPlay) {
                    if (val === 20 && vm.totalRestSec >= 25 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_rest_20_sec_voice) {
                        vm.handlePlayAudioPrompt('twentySecLeft');
                    } else if(val === 10 && vm.totalRestSec >= 15 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_rest_10_sec_voice) {
                        vm.handlePlayAudioPrompt('tenSecLeft');
                    } else if(val === 5 && vm.totalRestSec >= 10 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_rest_5_sec_voice) {
                        vm.handlePlayAudioPrompt('fiveSecLeft');
                    }
                }

                if (vm.activeWorkoutBlockIndex == vm.selectedWorkoutBlockList.length - 1 && vm.restMinLeft == 0 && val == 0 && vm.blockExerciseIndex == vm.selectedWorkoutBlock.exercises.length - 1 && vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                    if (vm.selectedWorkoutBlock.perform_type == 2 && vm.circuitRoundCount != vm.selectedWorkoutBlock.circuit_settings.round) {
                        vm.handlePlayAudioPrompt('nextExercise');

                        vm.playingNextStepAudio = true;

                        setTimeout(() => {
                            vm.playingNextStepAudio = false;
                        }, 2000);
                    } else {
                        if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets > 1 && vm.selectedExerciseSetsCount < vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets) {
                            vm.handlePlayAudioPrompt('nextExercise');

                            vm.playingNextStepAudio = true;

                            setTimeout(() => {
                                vm.playingNextStepAudio = false;
                            }, 2000);
                        } else {
                            vm.handlePlayAudioPrompt('lastExercise');
                        }
                    }
                } else if (vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length && vm.restMinLeft == 0 && val == 0) {
                    if ( vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                        if ((vm.blockExerciseIndex > 0 && vm.blockExerciseIndex < vm.selectedWorkoutBlock.exercises.length - 1)) {
                            if (!vm.supersetStarted) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            }
                        } else if (vm.blockExerciseIndex == vm.selectedWorkoutBlock.exercises.length - 1 && vm.navTab !== 'workout') {
                            if (vm.selectedWorkoutBlockExercise.measure_type == 2 && vm.circuitRoundCount == vm.selectedWorkoutBlock.circuit_settings.round) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            } else if (vm.selectedWorkoutBlockExercise.measure_type == 1) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            }
                        }
                    }

                    if (vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) {
                        if (vm.circuitRoundCount > 0 && vm.circuitRoundCount < vm.selectedWorkoutBlock.circuit_settings.round && vm.selectedWorkoutBlock.circuit_settings.round > 1) {
                            if (vm.blockExerciseIndex == 0 && vm.selectedWorkoutBlock.is_round_voice && vm.selectedWorkoutBlock.has_round_start_voice) {
                                if (vm.circuitRoundCount > 1) {
                                    vm.handlePlayAudioPrompt('nextRound');
                                }
                            } else if (vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            }
                        } else if (vm.circuitRoundCount == vm.selectedWorkoutBlock.circuit_settings.round && vm.selectedWorkoutBlock.circuit_settings.round > 1) {
                            if (vm.blockExerciseIndex == 0 && vm.selectedWorkoutBlock.is_round_voice && vm.selectedWorkoutBlock.has_round_start_voice) {
                                if (vm.circuitRoundCount > 1) {
                                    vm.handlePlayAudioPrompt('finalRound');
                                }
                            } else if (vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            }
                        }
                    }
                }
            },

            workSecLeft (val) {
                const vm = this;

                if (vm.workMinLeft == 0 && vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_work_voice && !vm.skipAudioPlay && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type !== 1) {
                    if (val === 20 && vm.totalWorkSec >= 25 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_work_20_sec_voice) {
                        vm.handlePlayAudioPrompt('twentySecLeftWork');
                    } else if(val === 10 && vm.totalWorkSec >= 15 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_work_10_sec_voice) {
                        vm.handlePlayAudioPrompt('tenSecLeftWork');
                    } else if(val === 5 && vm.totalWorkSec >= 10 && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_work_5_sec_voice) {
                        vm.handlePlayAudioPrompt('fiveSecLeftWork');
                    }
                }
            },

            isRestTimer (val) {
                const vm = this;

                if (!vm.isExerciseWorkTimer && !vm.startBlockScreen && val) {
                    if (vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length) {
                        if (vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) {
                            if (vm.blockExerciseIndex == 0) {
                                if (vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_round_voice && vm.selectedWorkoutBlock.has_round_complete_voice) {
                                    if (!vm.supersetStarted) {
                                        vm.handlePlayAudioPrompt('roundComplete');
                                    }
                                }

                                setTimeout(() => {
                                    if (vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_exercise_voice && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_exercise_complete_voice) {
                                        vm.handlePlayAudioPrompt('restExercise');
                                    }
                                }, 1500);
                            } else {
                                if (vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_exercise_voice && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_exercise_complete_voice) {
                                    vm.handlePlayAudioPrompt('restExercise');
                                }
                            }
                        } else if (vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].is_exercise_voice && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex].has_exercise_complete_voice) {
                            vm.handlePlayAudioPrompt('restExercise');
                        }
                    }
                }
            },

            navTab (val) {
                const vm = this;

                if (vm.selectedWorkoutBlock.is_finish_voice) {
                    if (val === 'complete' && vm.selectedWorkoutBlock.has_workout_confirmation_voice) {
                        vm.handlePlayAudioPrompt('workoutComplete');

                        setTimeout(function() {
                            if (vm.navTab === 'complete') {
                                vm.handlePlayAudioPrompt('rateWorkout');
                            }
                        }, 5000);
                    } else if (val === 'logged'  && vm.selectedWorkoutBlock.has_log_workout_voice) {
                        if (workoutComplete.currentTime != 0) {
                            workoutComplete.pause();
                            workoutComplete.currentTime = 0;
                        }

                        if (rateWorkout.currentTime != 0) {
                            rateWorkout.pause();
                            rateWorkout.currentTime = 0;
                        }

                        setTimeout(() => {
                            vm.handlePlayAudioPrompt('workoutLogged');
                        }, 10);
                    }
                }
            },

            skipAudioPlay () {
                const vm = this;

                setTimeout(function () {
                    vm.skipAudioPlay = false;
                }, 1000);
            },

            skipExerciseMoreDetails (val) {
                const vm = this;

                if (val) {
                    if (vm.isRestTimer && !vm.stopRestFlag && vm.restCoundownTimerStarted) {
                        vm.stopStartRestCountdown();
                    }

                    if ((vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) && vm.isExerciseWorkTimer && !vm.stopWorkFlag && vm.workCoundownTimerStarted && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type !== 1) {
                        vm.stopStartWorkCountdown();
                    }
                } else {
                    if (vm.isRestTimer && !vm.stopRestFlag && vm.restCoundownTimerStarted) {
                        vm.stopStartRestCountdown();
                    }

                    if ((vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) && vm.isExerciseWorkTimer && !vm.stopWorkFlag && vm.workCoundownTimerStarted && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type !== 1) {
                        vm.stopStartWorkCountdown();
                    }
                }
            },

            'selectedWorkoutBlockExercise.is_rest' (rest) {
                const vm = this;

                if (rest == 0 && vm.selectedWorkoutBlock.perform_type == 1 && !vm.supersetStarted) {
                    vm.supersetStarted          = true;
                    vm.supersetExercisesRound   = vm.selectedWorkoutBlockExercise.sets;
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

            vm.selectedWorkout          = vm.workout ? JSON.parse(JSON.stringify(vm.workout)) : { blocks: [] };
            vm.selectedWorkoutBlockList = vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length ? JSON.parse(JSON.stringify(vm.selectedWorkout.blocks)) : [];

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : '';
            vm.handleWorkoutStats();
        },

        unmounted () {
            const vm = this;

            vm.clearStartBlockInterval();
            vm.clearRestInterval();
            vm.clearWorkInterval();
            vm.clearTotalInterval();
        },

        methods: {
            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            clearStartBlockInterval () {
                const vm = this;

                if (vm.startBlockInterval) {
                    clearInterval(vm.startBlockInterval);
                }
            },

            clearRestInterval () {
                const vm = this;

                if (vm.restInterval) {
                    clearInterval(vm.restInterval);
                }
            },

            clearWorkInterval () {
                const vm = this;

                if (vm.workInterval) {
                    clearInterval(vm.workInterval);
                }
            },

            clearTotalInterval () {
                const vm = this;

                if (vm.totalInterval) {
                    clearInterval(vm.totalInterval);
                }
            },

            handleStartWorkout () {
                const vm = this;

                vm.clearStartBlockInterval();
                vm.clearRestInterval();
                vm.clearWorkInterval();
                vm.clearTotalInterval();

                if (vm.selectedWorkoutBlockList[0]) {
                    vm.selectedWorkoutBlock = vm.selectedWorkoutBlockList[0];
                }

                if (!vm.isResumeWorkout) {
                    vm.resetStartWorkoutData();

                    vm.startBlockScreen = true;
                } else {
                    vm.handleResumeWorkout();
                }
            },

            handleStartBlockWorkout () {
                const vm = this;

                vm.startBlockScreen = true;
            },

            startBlockExercise () {
                const vm = this;

                vm.clearStartBlockInterval();
                vm.clearRestInterval();
                vm.clearWorkInterval();

                vm.isBackWorkout  = false;

                function handleBlockSetting () {
                    if (vm.activeWorkoutBlockIndex == 0) {
                        vm.totalCountdown();
                    }

                    vm.navTab               = 'details';
                    vm.workoutStarted       = true;
                    vm.isExerciseWorkTimer  = true;
                    vm.hasNextBtn           = true;
                    vm.startBlockScreen     = false;
                    vm.isRestTimer          = false;
                    vm.toggleBox            = false;

                    if (vm.activeWorkoutBlockIndex && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1] && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1].has_block_screen == 0) {
                    } else {
                        vm.setRestTimer(vm.selectedWorkoutBlock);
                    }

                    vm.setWorkTimer(vm.selectedWorkoutBlock);
                }

                vm.selectedWorkoutBlock         = vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length ? vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex] : {};
                vm.blockExerciseIndex           = 0;
                vm.circuitRoundCount            = 1;
                vm.selectedExerciseSetsCount    = 1;
                vm.supersetExercisesIndex       = null
                vm.supersetRoundCount           = 1;
                vm.supersetExercisesRound       = 0;

                if (vm.selectedWorkoutBlockExercise.is_rest == 0 && vm.selectedWorkoutBlock.perform_type == 1 && !vm.supersetStarted) {
                    vm.supersetExercisesIndex   = null;
                    vm.supersetStarted          = true;
                    vm.supersetExercisesRound   = vm.selectedWorkoutBlockExercise.sets;
                }

                if (vm.activeWorkoutBlockIndex && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1] && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1].has_block_screen == 0) {
                    vm.skipAudioPlay        = false;
                    vm.restMinLeft          = 0;
                    vm.restSecLeft          = 0;
                    vm.isExerciseWorkTimer  = false;
                }

                if (vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length) {
                    if (!vm.skipAudioPlay) {
                        if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 1) {
                            if (vm.activeWorkoutBlockIndex && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex] && vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex - 1].has_block_screen == 0) {
                                vm.handlePlayAudioPrompt('nextExercise');

                                vm.playingNextStepAudio = true;

                                setTimeout(() => {
                                    vm.playingNextStepAudio = false;
                                }, 2000);
                            } else {
                                vm.handlePlayAudioPrompt('firstExercise');
                            }
                        }

                        if ((vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) && vm.selectedWorkoutBlock.is_round_voice && vm.selectedWorkoutBlock.has_round_start_voice) {
                            setTimeout(() => {
                                if (!vm.supersetStarted) {
                                    vm.handlePlayAudioPrompt('firstRound');
                                }
                            }, 1000);
                        }
                    }

                    vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex];

                    handleBlockSetting();
                } else {
                    const options = Helper.swalWarningOptions('Oops!', `Looks like you don't have any exercises in this block. Please proceed to the next block.`);

                    Swal.fire(options).then(({ isConfirmed }) => {
                        if (isConfirmed) {
                            handleBlockSetting();

                            vm.handleCompleteWorkoutBlock();
                        }
                    });
                }
            },

            startBlockCountdown () {
                const vm = this;

                vm.clearStartBlockInterval();

                vm.startBlockInterval = setInterval(() => {
                    if (!vm.stoppedBlockScreenTimer) {
                        if (vm.startBlockSecLeft === 0 && vm.startBlockMinLeft === 0) {
                            const block = vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length ? vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex] : {};

                            if (block && block.block_interval_type == 2) {
                                vm.startBlockExercise();
                            }

                            vm.clearStartBlockInterval();
                        } else if (vm.startBlockSecLeft > 0){
                            vm.startBlockSecLeft--;
                            vm.totalStartBlockSecLeft--;
                        } else {
                            if (vm.startBlockMinLeft >= 1) {
                                vm.startBlockMinLeft--;
                                vm.startBlockSecLeft = 59;
                                vm.totalStartBlockSecLeft--;
                            }
                        }
                    }
                }, 1000);
            },

            stopStartCountdown () {
                const vm = this;

                vm.stoppedBlockScreenTimer = !vm.stoppedBlockScreenTimer;

                if (!vm.stoppedBlockScreenTimer) {
                    vm.startBlockInterval;
                }
            },

            totalCountdown () {
                const vm = this;

                vm.clearTotalInterval();

                vm.totalInterval = setInterval(() => {
                    if (vm.navTab == 'complete') {
                        clearInterval(vm.totalInterval);
                    } else if (vm.totalWorkoutSec < 59) {
                        vm.totalWorkoutSec++;
                    } else if (vm.totalWorkoutMin < 59) {
                            vm.totalWorkoutMin++;
                            vm.totalWorkoutSec = 0;
                    } else {
                        if (vm.totalWorkoutHrs >= 0) {
                            vm.totalWorkoutHrs++;
                            vm.totalWorkoutMin = 0;
                            vm.totalWorkoutSec = 0;
                        }
                    }
                }, 1000)
            },

            restCountdown () {
                const vm = this;

                vm.clearRestInterval();

                if (vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && (vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].is_rest == 0 || (parseInt(vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].rest_minutes) == 0 && parseInt(vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].rest_seconds) == 0))) {
                    vm.handleCompleteRestTime();
                    vm.restCoundownTimerStarted = false;
                } else {
                    vm.restInterval = setInterval(() => {
                        if (!vm.stoppedRestTimer) {
                            if (vm.restSecLeft === 0 && vm.restMinLeft === 0) {
                                clearInterval(vm.restInterval);
                                vm.isRestTimer = false;

                                vm.handleCompleteRestTime();
                                vm.restCoundownTimerStarted = false;
                            } else if (vm.restSecLeft > 0){
                                vm.restSecLeft--;
                                vm.totalRestSecLeft--;
                                vm.restCoundownTimerStarted = true;
                            } else {
                                if (vm.restMinLeft >= 1) {
                                    vm.restMinLeft--;
                                    vm.restSecLeft = 59;
                                    vm.totalRestSecLeft--;
                                    vm.restCoundownTimerStarted = true;
                                }
                            }
                        }
                    }, 1000)
                }
            },

            stopStartRestCountdown () {
                const vm = this;

                vm.stoppedRestTimer = !vm.stoppedRestTimer;

                if (!vm.stoppedRestTimer) {
                    vm.restCountdown();
                }
            },

            workCountdown () {
                const vm = this;

                vm.clearWorkInterval();

                vm.workInterval = setInterval(() => {
                    if (!vm.stoppedWorkTimer) {
                        if (vm.workSecLeft === 0 && vm.workMinLeft === 0) {
                            clearInterval(vm.workInterval);
                            vm.hasNextBtn = true;

                            vm.handleCompleteExercise();

                            vm.workCoundownTimerStarted = false;
                        } else if (vm.workSecLeft > 0) {
                            vm.workSecLeft--;
                            vm.totalWorkSecLeft--;
                            vm.workCoundownTimerStarted = true;
                        } else {
                            if (vm.workMinLeft >= 1) {
                                vm.workMinLeft--;
                                vm.workSecLeft = 59;
                                vm.totalWorkSecLeft--;
                                vm.workCoundownTimerStarted = true;
                            }
                        }
                    }
                }, 1000)
            },

            stopStartWorkCountdown () {
                const vm = this;

                vm.stoppedWorkTimer = !vm.stoppedWorkTimer;

                if (!vm.stoppedWorkTimer) {
                    vm.workCountdown();
                }
            },

            skipStartScreen () {
                const vm = this;

                vm.startBlockMinLeft        = 0;
                vm.startBlockSecLeft        = 0;
                vm.totalStartBlockSecLeft   = 0;
                vm.totalStartBlockSec       = 0;
            },

            getWorkoutBlockDetail (block) {
                const vm = this;

                const exerciseType    = block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round}-rounds`;
                const exerciseType2   = block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round}-round`;
                const exerciseCount   = block.exercises && block.exercises.length ? block.exercises.length : 0;
                const blockName       = block.name;
                const isWorkDurations = block.circuit_settings && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec) ? true : false;
                const isRestDurations = block.circuit_settings && (block.circuit_settings.rest_min || block.circuit_settings.rest_sec) ? true : false;
                const restDurations   = isRestDurations ? (block.circuit_settings.rest_min ? `${block.circuit_settings.rest_min}-minute` : '') + (block.circuit_settings.rest_sec ? `${block.circuit_settings.rest_sec}-seconds` : '') : '';

                let workDurations;

                if (block.circuit_settings.amrap) {
                    workDurations   = isWorkDurations ? (block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min}-minute ` : ' ') + (block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec}-seconds ` : ' ') + 'work to' : '';
                } else {
                    workDurations   = isWorkDurations ? (block.circuit_settings.work_min ? `${block.circuit_settings.work_min}-minute ` : ' ') + (block.circuit_settings.work_sec ? `${block.circuit_settings.work_sec}-seconds ` : ' ') + 'work to' : '';
                }

                return `This is an ${exerciseType2} ${exerciseCount}-exercise circuit`;

                //return `This is a ${exerciseType}, ${exerciseCount} exercises ${blockName} circuit with a ${workDurations} ${restDurations} rest interval.`
            },

            repCalculate () {
                const vm = this;
                if (vm.oneRepMaxCalculations[vm.activeWorkoutBlockIndex][vm.blockExerciseIndex].reps) {
                    vm.repConfirm    = false;
                    vm.hasNextBtn    = false;
                } else {
                    vm.repConfirm    = true;
                }
            },

            setRestTimer (block) {
                const vm = this;

                vm.restMinLeft = 0;
                vm.restSecLeft = 0;

                if (block.exercises && block.exercises.length) {
                    if (block.perform_type == 1) {
                        if (vm.selectedWorkoutBlockExercise.is_rest && (vm.selectedWorkoutBlockExercise.rest_minutes || vm.selectedWorkoutBlockExercise.rest_seconds)) {
                            // vm.skipAudioPlay  = true;
                            vm.restMinLeft    = vm.selectedWorkoutBlockExercise.rest_minutes ? vm.selectedWorkoutBlockExercise.rest_minutes : 0;
                            vm.restSecLeft    = vm.selectedWorkoutBlockExercise.rest_seconds ? vm.selectedWorkoutBlockExercise.rest_seconds : 0;

                            vm.handleCalculateRestTime();
                        }
                    } else if (block.perform_type == 2) {
                        if (vm.selectedWorkoutBlockExercise.is_rest && (vm.selectedWorkoutBlockExercise.rest_minutes || vm.selectedWorkoutBlockExercise.rest_seconds)) {
                            // vm.skipAudioPlay  = true;
                            // vm.restMinLeft    = block.circuit_settings && block.circuit_settings.rest_min ? block.circuit_settings.rest_min : 0;
                            // vm.restSecLeft    = block.circuit_settings && block.circuit_settings.rest_sec ? block.circuit_settings.rest_sec : 0;

                            vm.restMinLeft    = vm.selectedWorkoutBlockExercise.rest_minutes ? vm.selectedWorkoutBlockExercise.rest_minutes : 0;
                            vm.restSecLeft    = vm.selectedWorkoutBlockExercise.rest_seconds ? vm.selectedWorkoutBlockExercise.rest_seconds : 0;

                            vm.handleCalculateRestTime();
                        }
                    }
                }
            },

            setWorkTimer (block) {
                const vm = this;

                vm.clearWorkInterval();

                vm.workMinLeft         = 0;
                vm.workSecLeft         = 0;
                vm.isExerciseWorkTimer = true;

                setTimeout(() => {
                    if (block.exercises && block.exercises.length && block.has_work_interval) {
                        if ((block.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2)) && block.exercises[vm.blockExerciseIndex].measure_type !== 1) {
                            if (block.circuit_settings.has_duration) {
                                // vm.workMinLeft      = block.circuit_settings.work_min ? block.circuit_settings.work_min : 0;
                                // vm.workSecLeft      = block.circuit_settings.work_sec ? block.circuit_settings.work_sec : 0;
                                vm.workMinLeft      = vm.selectedWorkoutBlockExercise.exercise_minutes ? parseInt(vm.selectedWorkoutBlockExercise.exercise_minutes) : 0;
                                vm.workSecLeft      = vm.selectedWorkoutBlockExercise.exercise_seconds ? parseInt(vm.selectedWorkoutBlockExercise.exercise_seconds) : 0;
                                vm.stoppedWorkTimer = block.work_interval_type == 1 ? true : false;

                                vm.workCountdown();
                                vm.handleCalculateWorkTime();
                            } else {
                                if (vm.selectedWorkoutBlockExercise.measure_type == 2) {
                                    vm.workMinLeft      = vm.selectedWorkoutBlockExercise.exercise_minutes ? parseInt(vm.selectedWorkoutBlockExercise.exercise_minutes) : 0;
                                    vm.workSecLeft      = vm.selectedWorkoutBlockExercise.exercise_seconds ? parseInt(vm.selectedWorkoutBlockExercise.exercise_seconds) : 0;
                                    vm.stoppedWorkTimer = block.work_interval_type == 1 ? true : false;

                                    vm.workCountdown();
                                    vm.handleCalculateWorkTime();
                                } else {
                                    vm.isExerciseWorkTimer = false;
                                }
                            }
                        } else if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 1) {
                            if (vm.selectedWorkoutBlockExercise.measure_type == 2) {
                                vm.workMinLeft      = vm.selectedWorkoutBlockExercise.exercise_minutes ? parseInt(vm.selectedWorkoutBlockExercise.exercise_minutes) : 0;
                                vm.workSecLeft      = vm.selectedWorkoutBlockExercise.exercise_seconds ? parseInt(vm.selectedWorkoutBlockExercise.exercise_seconds) : 0;
                                vm.stoppedWorkTimer = block.work_interval_type == 1 ? true : false;

                                vm.workCountdown();
                                vm.handleCalculateWorkTime();
                            } else {
                                vm.isExerciseWorkTimer = false;
                            }
                        }
                    }
                }, 10);
            },

            handleSkipExercise (restartBlock = false) {
                const vm = this;

                vm.clearWorkInterval();

                vm.workMinLeft    = 0;
                vm.workSecLeft    = 0;
                vm.isBackWorkout  = false;

                vm.handleCompleteExerciseTime(restartBlock);
            },

            handleCompleteExerciseTime (restartBlock = false) {
                const vm = this;

                vm.isRestTimer         = true;
                vm.isExerciseWorkTimer = false;
                vm.toggleBox           = true;

                if (vm.selectedWorkoutBlock.exercises) { // Check exercise exists in block
                    if (vm.selectedWorkoutBlock.perform_type == 1 && (vm.selectedWorkoutBlockExercise.is_rest == 0 || !isNaN(vm.supersetExercisesIndex)) && vm.supersetRoundCount != vm.supersetExercisesRound && vm.supersetStarted) {
                        if (vm.supersetExercisesIndex == null) {
                            vm.supersetRoundCount           = 1;
                            vm.selectedExerciseSetsCount    = 1;
                            vm.supersetExercisesIndex       = vm.blockExerciseIndex;
                            vm.supersetExercisesRound       = vm.selectedWorkoutBlockExercise.sets;
                        }

                        if (vm.selectedWorkoutBlockExercise.is_rest) {
                            if (vm.supersetRoundCount < vm.supersetExercisesRound) {
                                vm.setRestTimer(vm.selectedWorkoutBlock);
                                vm.restCountdown();

                                vm.blockExerciseIndex = vm.supersetExercisesIndex;
                                vm.supersetRoundCount++;
                                vm.selectedExerciseSetsCount++;

                                vm.handleSelectBlockExercise();
                            }
                        } else {
                            if (vm.selectedWorkoutBlockExercise.is_rest == 0 && vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) {
                                vm.setRestTimer(vm.selectedWorkoutBlock);
                                vm.restCountdown();

                                vm.blockExerciseIndex = vm.supersetExercisesIndex;
                                vm.supersetRoundCount++;
                                vm.selectedExerciseSetsCount++;
                            } else {
                                if (vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) {
                                    if (vm.selectedWorkoutBlock.exercises.length >= 2) {
                                        vm.supersetRoundCount++;
                                        vm.blockExerciseIndex--;
                                    }
                                } else {
                                    vm.blockExerciseIndex++;
                                }

                                if (vm.selectedWorkoutBlock.exercises.length == 1) {
                                    vm.supersetRoundCount++;
                                }

                                if (vm.selectedWorkoutBlockExercise.is_rest == 0) {
                                    vm.handleCompleteRestTime();
                                    vm.restCoundownTimerStarted = false;
                                }
                            }

                            vm.handleSelectBlockExercise();
                        }
                    } else if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets > 1 && vm.selectedExerciseSetsCount < vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets && !vm.supersetStarted) { // Check exercise is rest
                        vm.setRestTimer(vm.selectedWorkoutBlock);

                        vm.stoppedRestTimer = vm.selectedWorkoutBlock.rest_interval_type == 1 ? true : false;
                        vm.restCountdown();

                        if (restartBlock) {
                            vm.blockExerciseIndex = 0;
                            vm.selectedExerciseSetsCount++;
                        } else {
                            vm.selectedExerciseSetsCount++;
                        }

                        vm.handleSelectBlockExercise();
                    } else if (vm.selectedWorkoutBlock.exercises.length - 1 > vm.blockExerciseIndex) { // Check next exercise available select next exercise
                        vm.setRestTimer(vm.selectedWorkoutBlock);

                        vm.stoppedRestTimer = vm.selectedWorkoutBlock.rest_interval_type == 1 ? true : false;
                        vm.restCountdown();

                        vm.blockExerciseIndex++;

                        if (!vm.supersetStarted && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex + 1] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex + 1].is_rest == 0 && vm.supersetRoundCount == vm.supersetExercisesRound) {
                            vm.supersetRoundCount           = 1;
                            vm.selectedExerciseSetsCount    = 1;
                        }

                        vm.selectedExerciseSetsCount = 1;
                        vm.handleSelectBlockExercise();
                    } else if (vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) { // For select next block
                        if (restartBlock) {
                            vm.setRestTimer(vm.selectedWorkoutBlock);

                            vm.stoppedRestTimer = vm.selectedWorkoutBlock.rest_interval_type == 1 ? true : false;
                            vm.restCountdown();

                            vm.blockExerciseIndex = 0;
                            vm.circuitRoundCount++;
                            vm.selectedExerciseSetsCount = 1;

                            vm.handleSelectBlockExercise();
                        } else {
                            vm.handleCompleteWorkoutBlock();
                        }
                    }

                    if (vm.supersetStarted && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex - 1] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex - 1].is_rest == 1 && vm.supersetRoundCount == vm.supersetExercisesRound) {
                        vm.supersetStarted          = false;
                        vm.supersetExercisesIndex   = null;
                    }
                }
            },

            handleCompleteWorkoutBlock () {
                const vm = this;

                if (vm.selectedWorkoutBlockList.length -1 > vm.activeWorkoutBlockIndex) { //Check if next block available && select next exercise
                    vm.blockExerciseIndex = 0;
                    vm.activeWorkoutBlockIndex++;

                    vm.handleSelectWorkoutBlock();
                    vm.handleStartBlockWorkout();
                } else if (vm.selectedWorkoutBlockList.length -1 == vm.activeWorkoutBlockIndex) {
                    vm.isRestTimer         = false;
                    vm.isExerciseWorkTimer = false;
                    vm.toggleBox           = false;
                    vm.startBlockScreen    = false;
                    vm.workoutStarted      = false;
                    vm.navTab              = 'complete';

                    vm.clearRestInterval();
                    vm.clearWorkInterval();
                }
            },

            handleCloseCompleteSection () {
                const vm = this;

                vm.navTab         = 'details';
                vm.workoutStarted = true;

                if (!vm.selectedWorkoutBlock.exercises || !vm.selectedWorkoutBlock.exercises.length) {
                    vm.handlePreviousWorkoutBlock();
                }
            },

            handleBackWorkout () {
                const vm = this;

                if (!vm.isBackWorkout) {
                    vm.isBackWorkout = true;
                    vm.savedWorkout  =  {
                                            blockId: vm.selectedWorkoutBlock.id,
                                            exerciseId: vm.selectedWorkoutBlockExercise.id,
                                            blockRound: vm.circuitRoundCount,
                                            exerciseRound: vm.selectedExerciseSetsCount,
                                            tab: 'details',
                                            totalWorkoutMin: vm.totalWorkoutMin,
                                            totalWorkoutSec: vm.totalWorkoutSec,
                                            totalWorkoutHrs: vm.totalWorkoutHrs,
                                        };
                }

                vm.isExerciseWorkTimer = true;
                vm.workoutStarted      = true;
                vm.startBlockScreen    = false;
                vm.isRestTimer         = false;
                vm.toggleBox           = false;
                vm.repConfirm          = false;

                if (vm.blockExerciseIndex != 0) {
                    vm.blockExerciseIndex--;
                    vm.handleSelectBlockExercise();
                } else {
                    vm.handlePreviousWorkoutBlock();
                }
            },

            handlePreviousWorkoutBlock () {
                const vm = this;

                if (vm.blockExerciseIndex == 0) {
                    if (vm.activeWorkoutBlockIndex != 0) {
                        vm.activeWorkoutBlockIndex--;
                        vm.handleSelectWorkoutBlock();

                        if (vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises && vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises.length) {
                            vm.blockExerciseIndex = vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises.length - 1;

                            vm.handleSelectBlockExercise();

                            if (vm.selectedWorkoutBlock.perform_type == 2) {
                                vm.circuitRoundCount = vm.selectedWorkoutBlock.circuit_settings.round;
                            }
                        } else {
                            vm.handlePreviousWorkoutBlock();
                        }
                    } else {
                        vm.isResumeWorkout = 1;
                        vm.navTab = 'workout';
                        vm.startBlockScreen = false;
                        vm.workoutStarted   = false;
                        vm.clearStartBlockInterval();
                        vm.clearRestInterval();
                        vm.clearWorkInterval();
                        vm.clearTotalInterval();
                    }
                }
            },

            handleSelectBlockExercise () {
                const vm = this;

                vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length ? vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] : {};
            },

            handleSelectWorkoutBlock () {
                const vm = this;

                vm.selectedWorkoutBlock         = vm.selectedWorkoutBlockList && vm.selectedWorkoutBlockList.length ? vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex] : { exercises: [] };
                vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length ? vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] : {};
            },

            handleSkipRest () {
                const vm = this;

                vm.clearRestInterval();

                vm.skipAudioPlay  = true;
                vm.restMinLeft    = 0;
                vm.restSecLeft    = 0;

                vm.handleCompleteRestTime();
            },

            handleCompleteRestTime () {
                const vm = this;

                vm.isRestTimer         = false;
                vm.isExerciseWorkTimer = true;
                vm.toggleBox           = false;

                if (vm.restMinLeft == 0 && vm.restSecLeft == 0) {
                    if (vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length && vm.restMinLeft == 0) {
                        if (vm.selectedWorkoutBlock.perform_type == 2 || (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2 && !vm.supersetStarted)) {
                            if (vm.circuitRoundCount == 1 && vm.selectedWorkoutBlock.circuit_settings.round == 1) {
                                if ((vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) && (vm.selectedWorkoutBlockList.length - 1 == vm.activeWorkoutBlockIndex)) {
                                } else if (vm.selectedWorkoutBlockExercise.measure_type == 2 && vm.circuitRoundCount == vm.selectedWorkoutBlock.circuit_settings.round) {
                                    if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets > 1 && !vm.supersetStarted) {
                                    } else {
                                        setTimeout(() => {
                                            if (vm.selectedWorkoutBlockList.length - 1 == vm.activeWorkoutBlockIndex && vm.blockExerciseIndex ==  vm.selectedWorkoutBlock.exercises.length - 1) {
                                                vm.handlePlayAudioPrompt('lastExercise');
                                            } else {
                                                vm.handlePlayAudioPrompt('nextExercise');

                                                vm.playingNextStepAudio = true;

                                                setTimeout(() => {
                                                    vm.playingNextStepAudio = false;
                                                }, 2000);
                                            }
                                        }, 100)
                                    }
                                } else if (vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                                    vm.handlePlayAudioPrompt('nextExercise');

                                    vm.playingNextStepAudio = true;

                                    setTimeout(() => {
                                        vm.playingNextStepAudio = false;
                                    }, 2000);
                                }
                            } else if (vm.selectedWorkoutBlock.circuit_settings.round > 1 && (vm.selectedWorkoutBlockExercise.is_rest == 0 || (parseInt(vm.selectedWorkoutBlockExercise.rest_minutes) == 0 && parseInt(vm.selectedWorkoutBlockExercise.rest_seconds) == 0))) {
                                if ((vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) && (vm.selectedWorkoutBlockList.length - 1 == vm.activeWorkoutBlockIndex)) {
                                } else if (vm.selectedWorkoutBlockExercise.measure_type == 2 && vm.circuitRoundCount == vm.selectedWorkoutBlock.circuit_settings.round) {
                                    if (vm.selectedWorkoutBlock.perform_type == 1 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex] && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].measure_type == 2 && vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex].sets > 1 && !vm.supersetStarted) {
                                    } else {
                                        setTimeout(() => {
                                            if (vm.selectedWorkoutBlockList.length - 1 == vm.activeWorkoutBlockIndex && vm.blockExerciseIndex ==  vm.selectedWorkoutBlock.exercises.length - 1) {
                                                vm.handlePlayAudioPrompt('lastExercise');
                                            } else {
                                                vm.handlePlayAudioPrompt('nextExercise');

                                                vm.playingNextStepAudio = true;

                                                setTimeout(() => {
                                                    vm.playingNextStepAudio = false;
                                                }, 2000);
                                            }
                                        }, 100)
                                    }
                                } else if (vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                                    vm.handlePlayAudioPrompt('nextExercise');

                                    vm.playingNextStepAudio = true;

                                    setTimeout(() => {
                                        vm.playingNextStepAudio = false;
                                    }, 2000);
                                }
                            }
                        } else if (vm.selectedWorkoutBlock.perform_type == 1 && (vm.selectedWorkoutBlockExercise.is_rest == 0 || !isNaN(vm.supersetExercisesIndex)) && vm.supersetStarted) {
                            if (vm.selectedWorkoutBlock.is_exercise_voice && vm.selectedWorkoutBlock.has_exercise_start_voice) {
                                setTimeout(() => {
                                    if (vm.supersetRoundCount == vm.supersetExercisesRound && vm.selectedWorkoutBlock.exercises.length - 1 == vm.blockExerciseIndex) {
                                        if (vm.activeWorkoutBlockIndex == vm.selectedWorkoutBlockList.length - 1) {
                                            vm.handlePlayAudioPrompt('lastExercise');
                                        } else {
                                            vm.handlePlayAudioPrompt('nextExercise');

                                            vm.playingNextStepAudio = true;

                                            setTimeout(() => {
                                                vm.playingNextStepAudio = false;
                                            }, 2000);
                                        }
                                    } else {
                                        vm.handlePlayAudioPrompt('nextExercise');

                                        vm.playingNextStepAudio = true;

                                        setTimeout(() => {
                                            vm.playingNextStepAudio = false;
                                        }, 2000);
                                    }
                                }, 1)
                            }
                        }
                    }
                }

                vm.setWorkTimer(vm.selectedWorkoutBlock);
            },

            handleExerciseDetails (block, exercise, blockIndex, exerciseIndex) {
                const vm = this;

                vm.activeWorkoutBlockIndex  = blockIndex;
                vm.blockExerciseIndex       = exerciseIndex;
                vm.selectedDetailBlock      = JSON.parse(JSON.stringify(block));
                vm.selectedDetailExercise   = JSON.parse(JSON.stringify(exercise));
                vm.exerciseDetail           = true;
            },

            submitWorkoutLog () {
                const vm = this;

                vm.navTab = 'logged';
            },

            redirectCountdown (seconds) {
                const vm = this;

                function tick() {
                    vm.redirectSecLeft = seconds;

                    seconds--;
                    if (seconds >= 0) {
                        setTimeout(tick, 1000);
                    } else {
                        setTimeout(() => {
                            vm.hasRedirect = false;

                            if (vm.isPlan) {
                                vm.$parent.$parent.tab = 'plan';
                            } else {
                                vm.navTab = 'workout';

                                vm.clearStartBlockInterval();
                                vm.clearRestInterval();
                                vm.clearWorkInterval();
                                vm.clearTotalInterval();
                                vm.resetStartWorkoutData();
                            }
                        }, 500);
                    }
                }

                tick();
            },

            handleCalculateRestTime () {
                const vm = this;

                vm.totalRestSecLeft = vm.restMinLeft ? (vm.restMinLeft * 60) + vm.restSecLeft : vm.restSecLeft;
                vm.totalRestSec     = vm.totalRestSecLeft;
            },

            resetStartWorkoutData () {
                const vm = this;

                vm.workoutStarted          = false;
                vm.totalWorkoutMin         = 0;
                vm.totalWorkoutSec         = 0;
                vm.totalWorkoutHrs         = 0;
                vm.totalInterval           = null;
                vm.startBlockMinLeft       = 0;
                vm.startBlockSecLeft       = 15;
                vm.totalStartBlockSecLeft  = 15;
                vm.totalStartBlockSec      = 15;
                vm.startBlockInterval      = null;
                vm.stoppedBlockScreenTimer = false;
                vm.restMinLeft             = 0;
                vm.restSecLeft             = 0;
                vm.totalRestSec            = 30;
                vm.totalRestSecLeft        = 30;
                vm.totalWorkSec            = 30;
                vm.totalWorkSecLeft        = 30;
                vm.restInterval            = null;
                vm.stoppedRestTimer        = false;
                vm.workMinLeft             = 0;
                vm.workSecLeft             = 30;
                vm.workInterval            = null;
                vm.stoppedWorkTimer        = false;
                vm.hasNextBtn              = false;
                vm.toggleBox               = false;
                vm.isRestTimer             = false;
                vm.restPeriod              = 1;
                vm.incomplete              = false;
                vm.reminderType            = 1;
                vm.reminderDay             = 1;
                vm.reminderHr              = 7;
                vm.reminderMin             = 30;
                vm.workoutSave             = false;
                vm.repConfirm              = false;
                vm.activeWorkoutBlockIndex = 0;
                vm.blockExerciseIndex      = 0;
                vm.isExerciseWorkTimer     = true;
                vm.exerciseDetail          = false;
                vm.hasRedirect             = false;
                vm.redirectSecLeft         = 0;
                vm.workoutLogForm          = { difficulty: 0, enjoyment: 0, energy: 0, mood: 0, notes: '' };
                vm.savedWorkout            = {
                                                blockId: 0,
                                                exerciseId: 0,
                                                blockRound: 0,
                                                tab: 'workout',
                                                totalWorkoutMin: 0,
                                                totalWorkoutSec: 0,
                                                totalWorkoutHrs: 0,
                                             };
                vm.isResumeWorkout         = 0;
                vm.isBackWorkout           = false;
            },

            goTo (refName) {
                const vm          = this;
                let scrollContent = document.querySelector('.dashboard');
                let element       = vm.$refs[refName];

                if (scrollContent && element) {
                    let top = element.offsetTop;

                    setTimeout(function() {
                        scrollContent.scrollTo(0, top);
                    }, 100);
                }
            },

            handleCompleteExercise (loader = true, skipAudioPlay = false) {
                const vm = this;

                vm.skipAudioPlay  = skipAudioPlay;
                vm.exerciseLoader = loader;
                vm.isBackWorkout  = false;

                setTimeout(() => {
                    vm.skipAudioPlay  = skipAudioPlay;
                    vm.exerciseLoader = false;
                    vm.incomplete     = false;

                    if (vm.selectedWorkoutBlock.perform_type == 1) {
                        vm.handleSkipExercise();
                    } else if (vm.selectedWorkoutBlock.perform_type == 2) {
                        vm.handleSkipExercise(vm.circuitRoundCount != vm.selectedWorkoutBlock.circuit_settings.round);
                    }
                }, 400);

            },

            handleWorkoutStats () {
                const vm = this;

                if (vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length) {
                    let workMin     = 0;
                    let workSec     = 0;
                    let restMin     = 0;
                    let restSec     = 0;
                    let sets        = 0;
                    let totalHrs    = 0;
                    let totalMin    = 0;
                    let totalSec    = 0;
                    let totalRounds = 0;

                    vm.oneRepMaxCalculations = [];

                    vm.selectedWorkout.blocks.forEach((block, blockIndex) => {
                        if (block.exercises && block.exercises.length) {
                            vm.oneRepMaxCalculations[blockIndex] = [];

                            if (block.perform_type == 2) {
                                totalRounds += block.circuit_settings.round;
                            }

                            block.exercises.forEach((exercise, index) => {
                                vm.oneRepMaxCalculations[blockIndex][index] = { reps: '', weight_unit: 'lbs' };

                                if (block.perform_type == 2) {
                                    workMin += block.circuit_settings.round * exercise.exercise_minutes;
                                    workSec += block.circuit_settings.round * exercise.exercise_seconds;
                                    restMin += block.circuit_settings.round * exercise.rest_minutes;
                                    restSec += block.circuit_settings.round * exercise.rest_seconds;
                                } else {
                                    sets    += exercise.sets;
                                    workMin += exercise.sets;

                                    if (!exercise.is_variable) {
                                        restMin += exercise.sets * exercise.rest_minutes;
                                        restSec += exercise.sets * exercise.rest_seconds;
                                    } else {
                                        if (exercise.set_settings && exercise.set_settings.length) {
                                            exercise.set_settings =  exercise.set_settings.filter(({is_disable}) => is_disable == 0);
                                            exercise.set_settings.forEach((set, s) => {
                                                if (!set.is_disable) {
                                                    restMin += set.rest_minutes;
                                                    restSec += set.rest_seconds;
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    });

                    totalMin = parseInt(workMin) + parseInt(restMin);
                    totalSec = parseInt(workSec) + parseInt(restSec);
                    totalMin += Math.floor(parseInt(totalSec) / 60);
                    totalSec = parseInt(totalSec) % 60;
                    totalHrs = Math.floor(parseInt(totalMin) / 60);
                    totalMin = parseInt(totalMin) % 60;

                    vm.workoutStats = { sets, rounds: totalRounds, hours: totalHrs, minutes: totalMin, seconds: totalSec };
                }
            },

            handleExit (tab = 'workout') {
                const vm = this;

                if (tab == 'plan') {
                    vm.$parent.$parent.tab = 'plan';
                } else {
                    const resetWorkout = () => {
                                                vm.navTab = tab;
                                                vm.startBlockScreen = false;
                                                vm.workoutStarted   = false;
                                                vm.clearStartBlockInterval();
                                                vm.clearRestInterval();
                                                vm.clearWorkInterval();
                                                vm.clearTotalInterval();
                                                vm.selectedWorkoutBlock = {exercises: []};
                                            };

                    if (vm.navTab != 'logged') {
                        const options = Helper.swalConfirmOptionsWithDenyButton('Leave active workout?', 'Do you want save workout progress or restart?', 'Restart', 'Save');
                        options.showCancelButton = true;

                        Swal.fire(options).then(({ isConfirmed, isDismissed }) => {
                            if (!isDismissed) {
                                if (!isConfirmed) {
                                    vm.savedWorkout =  {
                                                            blockId: vm.selectedWorkoutBlock.id,
                                                            exerciseId: vm.selectedWorkoutBlockExercise.id,
                                                            blockRound: vm.circuitRoundCount,
                                                            exerciseRound: vm.selectedExerciseSetsCount,
                                                            tab: vm.navTab,
                                                            totalWorkoutMin: vm.totalWorkoutMin,
                                                            totalWorkoutSec: vm.totalWorkoutSec,
                                                            totalWorkoutHrs: vm.totalWorkoutHrs,
                                                        };

                                    vm.isResumeWorkout = 1;
                                    vm.skipExerciseMoreDetails = false;
                                } else {
                                    vm.isResumeWorkout = 0;
                                    vm.skipExerciseMoreDetails = false;
                                }

                                resetWorkout();
                            }
                        });
                    } else {
                        resetWorkout();
                    }
                }
            },

            handleCalculateWorkTime () {
                const vm = this;

                vm.totalWorkSecLeft = vm.workMinLeft ? (vm.workMinLeft * 60) + vm.workSecLeft : vm.workSecLeft;
                vm.totalWorkSec     = vm.totalWorkSecLeft;
            },

            playOneRepMaxAudio () {
                const vm = this;

                vm.handlePlayAudioPrompt('oneRepMaxAudio');
            },

            handlePlayAudioPrompt (audio) {
                const vm = this;

                if (vm.playingNextStepAudio && audio == 'nextExercise') {
                    return false;
                }

                const audioPrompts = new Map([
                                                ['firstBlock', firstBlock],
                                                ['nextBlock', nextBlock],
                                                ['finalBlock', finalBlock],
                                                ['blockComplete', blockComplete],
                                                ['fiveSecLeft', fiveSecLeft],
                                                ['tenSecLeft', tenSecLeft],
                                                ['twentySecLeft', twentySecLeft],
                                                ['firstExercise', firstExercise],
                                                ['lastExercise', lastExercise],
                                                ['nextExercise', nextExercise],
                                                ['startBlock', startBlock],
                                                ['restExercise', restExercise],
                                                ['workoutComplete', workoutComplete],
                                                ['workoutLogged', workoutLogged],
                                                ['rateWorkout', rateWorkout],
                                                ['roundComplete', roundComplete],
                                                ['finalRound', finalRound],
                                                ['firstRound', firstRound],
                                                ['nextRound', nextRound],
                                                ['oneRepMaxAudio', oneRepMaxAudio],
                                                ['twentySecLeftWork', twentySecLeftWork],
                                                ['tenSecLeftWork', tenSecLeftWork],
                                                ['fiveSecLeftWork', fiveSecLeftWork],
                                            ]);

                audioPrompts.forEach(audioPrompt => {
                    if (audioPrompt.currentTime != 0) {
                        audioPrompt.pause();
                        audioPrompt.currentTime = 0;
                    }
                });

                if (vm.navTab != 'workout' || vm.startBlockScreen) {
                    const selectedAudio = audioPrompts.get(audio);

                    setTimeout(() => {
                        if (selectedAudio) {
                            selectedAudio.play();
                        }
                    }, 10)
                } else if (audio == 'oneRepMaxAudio') {
                    const selectedAudio = audioPrompts.get(audio);

                    setTimeout(() => {
                        if (selectedAudio) {
                            selectedAudio.play();
                        }
                    }, 10)
                }
            },

            handleResumeWorkout () {
                const vm = this;

                vm.isResumeWorkout   = 0;
                vm.circuitRoundCount = vm.savedWorkout.blockRound;
                vm.navTab            = vm.savedWorkout.tab;
                vm.totalWorkoutMin   = vm.savedWorkout.totalWorkoutMin;
                vm.totalWorkoutSec   = vm.savedWorkout.totalWorkoutSec;
                vm.totalWorkoutHrs   = vm.savedWorkout.totalWorkoutHrs;
                vm.isBackWorkout     = false;
                vm.selectedExerciseSetsCount = vm.savedWorkout.exerciseRound;

                if (vm.selectedWorkoutBlockList && vm.selectedWorkoutBlockList.length) {
                    const blockIndex = vm.selectedWorkoutBlockList.findIndex(({id}) => id == vm.savedWorkout.blockId);

                    if (blockIndex != -1) {
                        vm.activeWorkoutBlockIndex = blockIndex;

                        if (vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises && vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises.length) {
                            const blockExerciseIndex = vm.selectedWorkoutBlockList[vm.activeWorkoutBlockIndex].exercises.findIndex(({id}) => id == vm.savedWorkout.exerciseId);

                            if (blockExerciseIndex != -1) {
                                vm.blockExerciseIndex = blockExerciseIndex;
                            } else {
                                const options = Helper.swalWarningOptions('Oops!', `Looks like you don't have any exercises in this block. Please proceed to the next block.`);

                                Swal.fire(options).then(({ isConfirmed }) => {
                                    if (isConfirmed) {
                                        vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex];
                                        vm.workoutStarted               = true;
                                        vm.isExerciseWorkTimer          = true;
                                        vm.hasNextBtn                   = true;
                                        vm.startBlockScreen             = false;
                                        vm.isRestTimer                  = false;
                                        vm.toggleBox                    = false;

                                        vm.setRestTimer(vm.selectedWorkoutBlock);
                                        vm.setWorkTimer(vm.selectedWorkoutBlock);
                                    }
                                });
                            }
                        }

                        if (vm.navTab == 'details') {
                            vm.handleSelectResumeWorkoutExercise();
                        }

                        vm.totalCountdown();
                    } else {
                        vm.activeWorkoutBlockIndex = 0;
                        vm.blockExerciseIndex      = 0;

                        vm.startBlockExercise();
                    }
                } else {
                    vm.activeWorkoutBlockIndex = 0;
                    vm.blockExerciseIndex      = 0;

                    vm.startBlockExercise();
                }
            },

            handleSelectResumeWorkoutExercise () {
                const vm = this;

                vm.selectedWorkoutBlock = vm.selectedWorkout.blocks && vm.selectedWorkout.blocks.length ? vm.selectedWorkout.blocks[vm.activeWorkoutBlockIndex] : {};

                if (vm.selectedWorkoutBlock.exercises && vm.selectedWorkoutBlock.exercises.length) {
                    vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex];
                    vm.workoutStarted               = true;
                    vm.isExerciseWorkTimer          = true;
                    vm.hasNextBtn                   = true;
                    vm.startBlockScreen             = false;
                    vm.isRestTimer                  = false;
                    vm.toggleBox                    = false;

                    vm.setRestTimer(vm.selectedWorkoutBlock);
                    vm.setWorkTimer(vm.selectedWorkoutBlock);
                } else {
                    const options = Helper.swalWarningOptions('Oops!', `Looks like you don't have any exercises in this block. Please proceed to the next block.`);

                    Swal.fire(options).then(({ isConfirmed }) => {
                        if (isConfirmed) {
                            vm.selectedWorkoutBlockExercise = vm.selectedWorkoutBlock.exercises[vm.blockExerciseIndex];
                            vm.workoutStarted               = true;
                            vm.isExerciseWorkTimer          = true;
                            vm.hasNextBtn                   = true;
                            vm.startBlockScreen             = false;
                            vm.isRestTimer                  = false;
                            vm.toggleBox                    = false;

                            vm.setRestTimer(vm.selectedWorkoutBlock);
                            vm.setWorkTimer(vm.selectedWorkoutBlock);
                        }
                    });
                }
            },

            accordion (e) {
                let el = e.currentTarget.parentElement;
                let allEl = document.querySelectorAll('.block_list .block_item');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('show');
                    }

                    el.classList.add('show');
                }
            },

            handleStartExercise (block, blockIndex, exercise, exerciseIndex) {
                const vm = this;

                vm.selectedWorkoutBlock = block;
                vm.setRestTimer(block);
                vm.restMinLeft = 0;
                vm.restSecLeft = 0;

                vm.stoppedRestTimer = block.rest_interval_type == 1 ? true : false;
                vm.restCountdown();

                vm.activeWorkoutBlockIndex  = blockIndex;
                vm.blockExerciseIndex       = exerciseIndex;

                vm.handleSelectBlockExercise();

                vm.skipExerciseBlock        = false;
                vm.skipExerciseMoreDetails  = false;
            },

            filterWorkTime (block) {
                let variableWorkTime = false;
                let text = '';
                const workMinutes = [];
                const workSeconds = [];
                const workReps    = [];

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        if (exercise.measure_type == 2) {
                            workMinutes.push(parseInt(exercise.exercise_minutes));
                            workSeconds.push(parseInt(exercise.exercise_seconds));

                            if (parseInt(exercise.exercise_minutes) !== parseInt(block.circuit_settings.work_min)) {
                                variableWorkTime = true;
                            }

                            if (parseInt(exercise.exercise_seconds) !== parseInt(block.circuit_settings.work_sec)) {
                                variableWorkTime = true;
                            }
                        } else if (exercise.measure_type == 1) {
                            if (exercise.is_failure && !exercise.is_reps_ranges) {
                                workReps.push(0);
                            } else if (exercise.is_reps_ranges) {
                                workReps.push(exercise.min_reps);
                            } else {
                                workReps.push(exercise.exercise_reps);
                            }
                        }
                    })
                }

                const uniqueMinutes = workMinutes.filter((value, index, array) => array.indexOf(value) === index);
                const uniqueSeconds = workSeconds.filter((value, index, array) => array.indexOf(value) === index);

                if ((uniqueMinutes.length > 1 || uniqueSeconds.length > 1) || workReps.length >= 1) {
                    variableWorkTime = true;
                } else {
                    variableWorkTime = false;
                }

                if (variableWorkTime) {
                    return 'variable';
                }

                if (uniqueMinutes[0]) {
                    text += `${uniqueMinutes[0] ? uniqueMinutes[0] : block.circuit_settings.work_min} min `;
                }

                if (uniqueSeconds[0]) {
                    text += `${uniqueSeconds[0] ? uniqueSeconds[0] : block.circuit_settings.work_sec} sec`;
                }

                return text;
            },

            filterRestTime (block) {
                let variableWorkTime = false;
                let text = '';
                let hasRestTime = false;
                const restMinutes = [];
                const restSeconds = [];

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        restMinutes.push(parseInt(exercise.rest_minutes));
                        restSeconds.push(parseInt(exercise.rest_seconds));

                        if (parseInt(exercise.rest_minutes) !== parseInt(block.circuit_settings.rest_min)) {
                            variableWorkTime = true;
                        }

                        if (parseInt(exercise.rest_seconds) !== parseInt(block.circuit_settings.rest_sec)) {
                            variableWorkTime = true;
                        }

                        if (exercise.is_rest) {
                            hasRestTime = true;
                        }
                    })
                }

                const uniqueMinutes = restMinutes.filter((value, index, array) => array.indexOf(value) === index);
                const uniqueSeconds = restSeconds.filter((value, index, array) => array.indexOf(value) === index);

                if (uniqueMinutes.length > 1 || uniqueSeconds.length > 1) {
                    variableWorkTime = true;
                } else {
                    variableWorkTime = false;
                }

                if (variableWorkTime) {
                    return 'variable rest intervals';
                }

                if (uniqueMinutes[0]) {
                    text += `${uniqueMinutes[0] ? uniqueMinutes[0] : block.circuit_settings.rest_min} min `;
                }

                if (uniqueSeconds[0]) {
                    text += `${uniqueSeconds[0] ? uniqueSeconds[0] : block.circuit_settings.rest_sec} sec `;
                }

                text += 'rest intervals';

                if (!hasRestTime) {
                    text = block.perform_type == 1 ? 'Super Set' : 'No rest'
                }

                return text;
            },

            blockHasWorkTime (block) {
                let hasWorkTime = false;

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        if (exercise.measure_type == 2) {
                            hasWorkTime = true;
                        }
                    })
                }

                return hasWorkTime;
            },

            filterLoadUnitCode (exercise) {
                return exercise.load_unit_code_v2 ? exercise.load_unit_code_v2 : exercise.load_unit_code.toLowerCase();
            },

            filterLoadNumber (exercise) {
                if (exercise.load_unit == 'Light' || exercise.load_unit == 'Medium' || exercise.load_unit == 'Heavy' || exercise.load_unit == 'Easy' || exercise.load_unit == 'Moderate' || exercise.load_unit == 'Hard') {
                    return '';
                } else if (exercise.load_number == 0) {
                    return '--';
                }

                return exercise.load_number;
            },
        },
    }
</script>

<style scoped>
    .dashboard{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .dashboard_content{
        background: #fff;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .tab .dashboard_content, .desk .dashboard_content{
        max-width: none;
        margin: 0;
        padding: 0 30px;
    }
    .cell .dashboard_content{
        display: flex;
        margin: 0;
        padding: 20px 15px 0 15px;
    }
    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 22px;
    }
    .desk .logo_img, .tab .logo_img{
        max-height: 30px;
    }
    .client_card {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #e9e9e9;
        position: sticky;
        top: 0;
        z-index: 1;
        box-shadow: none;
        border-radius: 0;
    }
    .client_card .card_header .user_box > img{
        width: 20px;
        height: 20px;
    }
    .desk .client_card .card_header .user_box > img, .tab .client_card .card_header .user_box > img{
        width: 25px;
        height: 25px;
    }
    .client_card .card_header .user_box h5{
        font-size: 11px;
        line-height: 14px;
    }
    .desk .client_card .card_header, .tab .client_card .card_header{
        padding: 15px 20px;
        background: #fff;
    }
    .cell .client_card .card_header{
        padding: 15px
    }
    .cell .client_card .card_header nav{
        margin-right: 0;
        padding-right: 0;
        border: 0;
    }
    .cell .client_card .card_header nav.workout_bar{
        flex: 0 0 auto;
    }
    .cell .client_card .card_header nav a{
        margin-left: auto;
    }
    .dashboard_footer{
        margin: 0 -15px;
        text-align: center ;
    }
    .dashboard_container{
        /* flex: 0 1 800px; */
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .dashboard_container .exercise_details{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .back_btn{
        color: #5a5a5a;
        display: flex;
        gap: 10px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        padding-right: 15px;
    }
    .leave_btn{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebebeb;
        border-radius: 15px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }
    .client_card .card_header .block_status{
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
    }

    .card_details{
        position: absolute;
        background: #fff;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }
    .card_details.active{
        min-height: 608px;
        max-height: 100%;
        top: 0;
        overflow-Y: scroll;
    }
    .card_details::-webkit-scrollbar{
        display: none;
    }
    .card_details .deatils_header{
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        /* gap: 20px; */
    }
    .card_details .exercise_details{
        padding: 30px 15px;
    }
    .desk .card_details .exercise_details, .tab .card_details .exercise_details{
        padding: 50px 30px;
    }

    .rep_confirmation{
        text-align: center;
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 0;
        height: auto;
        overflow: hidden;
        z-index: 3;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }
    .rep_confirmation.active{
        box-shadow: 0 -3px 25px rgba(0,0,0,0.15);
        max-height: 610px;
        height: auto;
        overflow: visible;
    }
    .rep_confirmation .setting_wpr{
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        padding: 25px;
        background: #fff;
        border-radius: 6px 6px 0 0;
    }
    .rep_confirmation .setting_wpr h3{
        font-size: 15px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        text-align:left;
        padding-bottom: 5px;
    }
    .rep_confirmation .field_wpr input, .rep_confirmation .form_grp .multiselect{
        height: 40px;
        min-height: 40px;
    }
    .rep_confirmation .form_grp .multiselect :deep(.multiselect-single-label-text){
        font-size: 13px;
    }
    .rep_confirmation .setting_wpr .primary_btn{
        margin: 10px 0;
        cursor: pointer;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        height: 45px;
        letter-spacing: 0.5px;
        border: 1px solid var(--var-submit-btn-color);
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
    }
    .tab .rep_confirm_box, .cell .rep_confirm_box{
        display: none;
    }
    .rep_confirm_box{
        padding: 20px;
        min-width: 250px;
        left: auto;
        right: 0;
        margin: 0;

    }
    .rep_confirm_box .setting_wpr h3{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        text-align:left;
        padding-bottom: 5px;
        margin: 0;
    }
    .rep_confirm_box .field_wpr input, .rep_confirm_box .form_grp .multiselect{
        height: 40px;
        min-height: 40px;
    }
    .rep_confirm_box .form_grp .multiselect :deep(.multiselect-single-label-text){
        font-size: 13px;
    }
    .rep_confirm_box .setting_wpr .primary_btn{
        margin: 10px 0;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        height: 45px;
        letter-spacing: 0.5px;
    }

    /*------- Plan --------*/

    .exercise_list{
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .exercise_item .ex_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .quick_guide{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .quick_guide ul{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
    }
    .desk .quick_guide ul, .tab .quick_guide ul{
        padding: 40px 0;
    }
    .quick_guide ul li{
        padding: 0 15px;
        border-right: 1px solid #d9d9d9;
    }
    .desk .quick_guide ul li, .tab .quick_guide ul li{
        padding: 0 40px;
    }
    .quick_guide ul li:last-child{
        border: 0;
    }
    .quick_guide ul li h5{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
        text-align:center;
    }
    .desk .quick_guide ul li h5, .tab .quick_guide ul li h5{
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 7px;
    }
    .quick_guide ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        text-align:center;
    }
    .desk .quick_guide ul li h6, .tab .quick_guide ul li h6{
        font-size: 13px;
        line-height: 16px;
    }
    .start_btn {
        min-width: 200px;
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
        height: 45px;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 21px;
        cursor: pointer;
        padding: 0 15px;
        margin: 10px auto;
    }
    .desk .start_btn, .tab .start_btn{
        min-width: 250px;
        height: 55px;
        font-size: 18px;
        line-height: 21px;
        margin: 20px auto;
    }
    .week_list{
        padding: 20px 0;
    }
    .week_list .day_guide{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 0 0 20px 0;
    }
    .week_list .day_guide label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 7px;
    }
    .desk .week_list .day_guide label, .tab .week_list .day_guide label{
        font-size: 13px;
        line-height: 16px;
        gap: 10px;
    }
    .week_list ul{
        position: relative;
    }
    .week_list ul:before{
        position: absolute;
        content: '';
        left: 11px;
        top: 60px;
        bottom: 60px;
        border-left: 1px solid #d9d9d9;
    }
    .desk .week_list ul:before, .tab .week_list ul:before{
        left: 14px;
    }
    .week_list ul li{
        padding: 10px 0;
        display: flex;
        align-items: center;
        position: relative;
    }
    .week_list ul li:before{
        position: absolute;
        content: '';
        right: 1px;
        left: 1px;
        top: calc(50% - 1px);
        border-bottom: 1px solid #d9d9d9;
    }
    .week_list ul li .bullet_icon{
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        border-radius: 50%;
        background: #e9e9e9;
        border: 1px solid #d9d9d9;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin-right: 10%;
    }
    .desk .week_list ul li .bullet_icon, .tab .week_list ul li .bullet_icon{
        width: 30px;
        height: 30px;
    }
    .exercise_item .exercise_info {
        flex: 0 1 580px;
        margin-left: auto;
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }
    .exercise_item ul li.completed{
        pointer-events: none;
    }
    .exercise_item ul li.completed .exercise_info{
        filter: grayscale(1);
    }
    .exercise_item .exercise_info h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .exercise_item .exercise_info h4 span{
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }
    .week_days_list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
        position: relative;
    }
    .week_days_list label{
        flex: 1 1 30px;
    }
    .week_days_list label .item_wpr{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }
    .week_days_list label .item_wpr.present_day{
        border: 1px solid #bebebe;
    }
    .week_days_list label span{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .desk .week_days_list label span, .tab .week_days_list label span{
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
    }
    .week_days_list label .day_stat, .week_list .day_guide label span{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #c1c1c1;
        position: relative;
        margin-top: 7px;
    }
    .week_days_list label .day_stat.active, .week_list .day_guide label span.active{
        background: var(--var-element-color);
    }
    .exercise_item ul li.completed .week_days_list:before{
        position: absolute;
        content: '';
        left: 15px;
        right: 15px;
        border-top: 1px solid #bebebe;
    }
    .exercise_item ul li.completed .week_days_list label .day_stat.active:before{
        position: absolute;
        content: '';
        left: 5px;
        bottom: 100%;
        height: 14px;
        border-left: 1px solid #bebebe;
    }
    .exercise_item ul li.completed .week_days_list label .day_stat.active:last-of-type:after{
        position: absolute;
        content: '';
        left: 5px;
        top: -10px;
        width: 250px;
        border-top: 1px solid #bebebe;
    }
    .timer{
        text-align: center;
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        z-index: 4;
        background: #fff;
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        /* height: 100%; */
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }
    .timer.active{
        padding: 15px;
        box-shadow: 0 -3px 25px rgba(0,0,0,0.15);
        overflow: visible;
        max-height: 150px;
        height: auto;
        bottom: 0;
    }
    .timer.active.lg{
        border-radius: 0;
        max-height: 610px;
        height: 610px;
        overflow-y: scroll;
    }
    .timer.task_details{
        overflow-y: auto;
        line-height: 0;
    }
    .timer.task_details::-webkit-scrollbar{
        width: 4px;
    }
    .timer.task_details::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .timer.task_details .instruction{
        width: 100%;
    }
    .timer.task_details.active{
        max-height: 555px;
        height: 555px;
        padding: 0 15px 30px 15px;
    }
    .timer .cross_btn{
        width: 40px;
        height: 20px;
        font-size: 12px;
        color: #121525;
        background: #e9e9e9;
        position: sticky;
        top: 0;
        z-index: 3;
        border-radius: 0 0 10px 10px;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
    }
    .timer .sticky_bar{
        position: sticky;
        z-index: 2;
        top: 0;
        padding: 30px 0;
        margin-top: 0;
        background: #fff;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .total_counter{
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        /* color: var(--var-timer-color); */
        color: #121525;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .total_counter h4{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding-bottom: 10px;
    }
    .timer .skip_exercise_btn{
        padding: 5px 15px;
        height: auto;
        flex-grow: 0;
        background: #2c3e50;
        border-radius: 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
    }
    .desk .timer.active.lg{
        max-height: calc(100vh - 75px);
    }
    .tab .timer.active.lg{
        max-height: 1050px;
    }
    .timer.active.lg::-webkit-scrollbar{
        display: none;
    }
    .timer .alert{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;
        width: calc(100% + 30px);
        margin: -5px -15px 15px -15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .timer .alert i{
        font-size: 16px;
        margin-left: 15px;
        color: #999;
        animation: mover2 0.5s infinite alternate;
    }
    .toggle_box{
        font-size: 16px;
        color: #777;
        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;
    }
    .timer .counter{
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        position: relative;
        display: flex;
        gap: 3px;
        align-items: center;
    }
    .desk .timer .counter, .tab .timer .counter{
        font-size: 20px;
        line-height: 25px;
    }
    .timer .counter h6{
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        padding-right: 7px;
    }
    .desk .timer .counter h6, .tab .timer .counter h6{
        font-size: 13px;
        line-height: 16px;
        padding-bottom: 7px;
    }
    .timer .counter h6 button {
        font-size: 9px;
        line-height: 12px;
        border-radius: 3px;
        padding: 2px 5px;
        color: var(--var-submit-btn-txt-color);
        background: var(--var-submit-btn-color);
        margin-right: 5px;
    }

    .timer .counter span {
        color: var(--var-timer-color);
    }
    .timer .other_counters{
        display: flex;
        padding: 0 0 5px;
    }
    .desk .timer .other_counters, .tab .timer .other_counters{
        padding: 0 0 10px;
    }
    .timer .other_counters .counter{
        padding: 0 15px;
    }
    .timer .other_counters .counter:not(:first-of-type){
        border-left: 1px solid #d9d9d9;
    }
    .timer .success {
        color: var(--var-element-color);
        font-size: 30px;
        padding-bottom: 10px;
    }

    .timer .copyright{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #999;
        margin-top: auto;
    }
    .rest_alert{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 10px 20px;
        position: relative;
    }
    .rest_alert label.capsule_btn {
        position: absolute;
        right: 0;
        top: 20px;
    }
    .rest_alert .score_circle{
        width: 160px;
        height: 160px;
        padding: 6px;
        position: relative;
    }
    .rest_alert .score_circle:before{
        position: absolute;
        content: '';
        left: 6px;
        right: 6px;
        top: 6px;
        bottom: 6px;
        border: 5px solid #fff;
        border-radius: 50%;
    }
    .desk .rest_alert .score_circle, .tab .rest_alert .score_circle{
        margin: 10px 0;
    }
    .rest_alert .score_circle svg{
        pointer-events: none;
    }
    .rest_alert .score_circle svg circle{
        transition: all 0.3s ease-in-out;
    }
    .rest_alert .score_circle .inner_circle{
        background: #fff;
        mix-blend-mode: darken;
    }
    .rest_alert .score_circle .inner_circle .score {
        font-size: 25px;
        line-height: 30px;
        font-weight: 300;
        color: #121525;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .rest_alert h5{
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
    }
    .desk .rest_alert h5, .tab .rest_alert h5{
        font-size: 18px;
        line-height: 25px;
    }
    .play_pause_btn{
        font-size: 10px;
        color: #757575;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: calc(50% - 10px);
        margin-top: 10px;
        cursor: pointer;
    }
    .play_pause_btn i.fa-play{
        margin-left: 3px;
    }
    .timer .counter .play_pause_btn{
        width: 20px;
        height: 20px;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }
    .rest_alert .score_circle .inner_circle .score.go,
    .rest_alert.has_play_pause_button .go{
        width: 50px;
        height: 50px;
        background: #fff;
    }
    .rest_alert .score_circle .inner_circle .score.go:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 1s infinite alternate;
    }
    .rest_alert .score_circle .inner_circle .score .hand_icon{
        position: absolute;
        left: calc(50% - 10px);
        top: 100%;
        margin-top: 5px;
        font-size: 25px;
        color: #2c3e50;
        animation: mover 1s infinite  alternate;
    }

    .timer .rounds{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 7px 0;
    }
    .desk .timer .rounds, .tab .timer .rounds{
        gap: 20px;
        font-size: 15px;
        line-height: 20px;
        padding: 10px 0;
    }
    .timer .rounds ul{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }
    .timer .rounds ul li{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #fff;
        background: #888;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .desk .timer .rounds ul li, .tab .timer .rounds ul li{
        width: 25px;
        height: 25px;
        font-size: 13px;
        line-height: 15px;
    }
    .timer .rounds ul li.active {
        background: var(--var-timer-color);
    }

    .timer .exercises{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 10px 0;
    }
    .desk .timer .exercises, .tab .timer .exercises{
        gap: 20px;
        font-size: 15px;
        line-height: 20px;
        padding: 15px 0;
    }
    .timer .exercises ul{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }
    .timer .exercises ul li{
        width: 16px;
        height: 16px;
        font-size: 9px;
        line-height: 16px;
        text-align: center;
        border-radius: 50%;
        background: #999;
        color: #fff;
        position: relative;
    }
    .desk .timer .exercises ul li, .tab .timer .exercises ul li{
        width: 30px;
        height: 6px;
        border-radius: 3px;
    }
    .timer .exercises ul li.blink{
        background: #fff;
        color: #121525;
    }
    .timer .exercises ul li.blink:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim2 0.5s infinite alternate;
    }

    .timer .exercises ul li.active {
        background: var(--var-timer-color);
    }
    .timer .exercise_details{
        width: 100%;
        text-align: left;
        padding-bottom: 25px;
    }
    .timer .exercise_details .instruction h4.headline{
        justify-content: center;
        text-align: center;
    }
    .desk .exercise_details{
        max-width: 990px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .desk .timer .exercise_details{
        max-width: 600px;
    }
    .footer_nav{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: auto 0 10px 0;
        /* margin: auto 0 -5px 0; */
    }
    .timer .skip_btn{
        font-size: 11px;
        line-height: 14px;
        padding: 5px;
        font-weight: 400;
        color: #121525;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .timer .back_btn{
        width: auto;
        height: auto;
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 400;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .counting_timer{
        font-size: 25px;
        line-height: 30px;
        color: #121525;
        font-weight: 300;
        margin-top: 15px;
        text-align: center;
    }
    .instant_text{
        font-size: 30px;
        line-height: 35px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 15px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
    }
    .instant_text span{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.5px;
        display: block;
        padding-bottom: 5px;
    }
    .footer_area{
        margin: auto 0 0 0;
    }
    .exercise_details .round_details{
        font-size: 16px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        text-align: center;
        padding: 10px 0;
    }
    .exercise_details .footer_nav{
        padding: 20px 0;
        margin: auto 0 0 0;
    }
    .footer_nav .go,
    .rest_alert.has_play_pause_button .go {
        font-size: 10px;
        color: #5a5a5a;
        width: 30px;
        height: 30px;
        font-size: 14px;
        border-radius: 50%;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 10px;
    }
    .footer_nav .go.lg,
    .rest_alert.has_play_pause_button .go.lg {
        width: 45px;
        height: 45px;
        font-size: 16px;
    }
    .footer_nav .go.lg label,
    .rest_alert.has_play_pause_button .go.lg label{
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        pointer-events: none;
    }
    .footer_nav .go i.fa-play,
    .rest_alert.has_play_pause_button .go i.fa-play {
        margin-left: 3px;
    }
    .footer_nav .go.ripple,
    .rest_alert.has_play_pause_button .go.ripple {
        background: #8d8d8d;
        color: #fff;
        font-size: 15px;
        line-height: 40px;
        font-weight: 400;
        animation: ripple 0.7s linear infinite;
    }
    .desk .timer .skip_btn, .tab .timer .skip_btn{
        font-size: 13px;
        line-height: 16px;
        padding: 5px 10px;
        bottom: 10px;
    }
    .desk .timer .back_btn, .tab .timer .back_btn{
        font-size: 13px;
        line-height: 16px;
        padding: 5px 10px;
        bottom: 10px;
    }

    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 rgba(141, 141, 141, 0.6), 0 0 0 5px rgba(141, 141, 141, 0.6);
        }
        100% {
            box-shadow: 0 0 0 5px rgba(141, 141, 141, 0.6), 0 0 0 10px rgba(141, 141, 141, 0);
        }
    }

    /* ------- workout start screen ------------ */

    .start_screen{
        justify-content: center;
        z-index: 5;
    }
    .block_stat{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1px;
        margin-bottom: 30px;
        margin-top: auto;
    }
    .block_stat li{
        background: #dbdbdb;
        padding: 8px 15px;
        min-width: 97px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        border-radius: 2px;
        transition: all 0.3s ease-in-out;
        position: relative;
    }
    .desk .block_stat li, .tab .block_stat li{
        font-size: 13px;
        line-height: 18px;
        padding: 10px 25px;
    }
    .block_stat li.active{
        background: var(--var-element-color);
        color: #fff;
    }
    .block_stat li.blink{
        background: #fff;
    }
    .block_stat li.blink:after {
        position: absolute;
        content: '';
        border-radius: 3px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 0.5s infinite alternate;
    }
    .timer .welcome_title{
        font-size: 20px;
        line-height: 30px;
        max-width: 270px;
    }
    .desk .timer .welcome_title, .tab .timer .welcome_title{
        font-size: 24px;
        line-height: 35px;
        max-width: 350px;
    }
    .welcome_title .title_sm{
        font-size: 15px;
        line-height: 22px;
    }
    .desk .welcome_title .title_sm, .tab .welcome_title .title_sm{
        font-size: 18px;
        line-height: 25px;
        margin-top: 15px;
    }
    .timer .block_note{
        font-size: 13px;
        line-height: 20px;
        /* font-weight: 500; */
        color: #121525;
        max-width: 300px;
        margin-top: 10px;
    }
    .desk .timer .block_note, .tab .timer .block_note{
        font-size: 15px;
        line-height: 23px;
        max-width: 380px;
    }
    .final_note {
        background: #f5f5f6;
        border-radius: 5px;
        padding: 15px;
        color: #444;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        margin: 20px 0;
        max-width: 300px;
    }
    .tab .final_note, .desk .final_note {
        padding: 17px 20px;
        font-size: 13px;
        line-height: 20px;
        margin-top: 30px;
        max-width: 380px;
    }

    /*------- Workout --------*/
    .plan_wrapper{
        padding: 20px 0 40px 0;
    }
    .plan_wrapper .swiper-wrapper{
        overflow: hidden;
    }
    .plan_wrapper .swiper-slide{
        width: 100% !important;
    }
    .plan_wrapper :deep(.swiper-pagination-bullet-active) {
        background: var(--var-element-color);
    }
    .plan_wrapper :deep(.swiper-button-next), .plan_wrapper :deep(.swiper-button-prev){
        top: 20px;
        width: 20px;
        height: 20px;
        margin: 0;
    }
    .plan_wrapper :deep(.swiper-button-next.swiper-button-disabled), .plan_wrapper :deep(.swiper-button-prev.swiper-button-disabled){
        opacity: 0;
    }
    .plan_wrapper :deep(.swiper-button-next){
        right: 0;
    }
    .plan_wrapper :deep(.swiper-button-next:after) {
        content: '\f30b';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }

    .plan_wrapper :deep(.swiper-button-prev) {
        left: 0;
    }

    .plan_wrapper :deep(.swiper-button-prev:after) {
        content: '\f30a';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }
    .block_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .desk .block_title, .tab .block_title{
        font-size: 18px;
        line-height: 23px;
        padding-bottom: 15px;
    }
    .exercise_item .plan_list {
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }
    .desk .exercise_item .plan_list, .tab .exercise_item .plan_list{
        padding: 15px 30px;
    }
    .exercise_item .plan_list .block_type{
        font-size: 11px;
        line-height: 14px;
        color: var(--var-element-text-color);
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }
    .exercise_item .plan_list .block_type .tag{
        background: #fff;
        padding: 3px 10px;
        border-radius: 12px;
    }
    .desk .exercise_item .plan_list .block_type, .tab .exercise_item .plan_list .block_type{
        font-size: 13px;
        line-height: 17px;
    }
    .exercise_item .plan_list .plan_info{
        font-size: 11px;
        line-height: 14px;
        color: var(--var-element-text-color);
        font-weight: 400;
        display: flex;
        align-items: center;
    }
    .desk .exercise_item .plan_list .plan_info, .tab .exercise_item .plan_list .plan_info{
        font-size: 13px;
        line-height: 17px;
    }
    .exercise_item .plan_list h4 span{
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }
    .exercise_item .plan_list ul{
        display: flex;
        flex-direction: column;
        padding: 0 0 15px 0;
    }
    .desk .exercise_item .plan_list ul, .tab .exercise_item .plan_list ul{
        gap: 20px;
        padding: 15px 0 20px 0;
    }
    .exercise_item .plan_list ul li{
        background: #fff;
        border-radius: 6px;
        display: flex;
        /* flex-wrap: wrap; */
        margin-top: 15px;
        cursor: pointer;
        position: relative;
    }
    .desk .exercise_item .plan_list ul li, .tab .exercise_item .plan_list ul li{
        gap: 20px;
    }
    .exercise_item .plan_list ul li .video_area{
        width: 33%;
        flex-shrink: 0;
    }
    .exercise_item .plan_list ul li .info_wpr{
        flex-grow: 1;
        padding: 10px;
    }
    .desk .exercise_item .plan_list ul li .info_wpr, .tab .exercise_item .plan_list ul li .info_wpr{
        width: calc(67% - 20px);
        padding: 15px 0;
    }
    .exercise_item .plan_list ul li .info_wpr h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .desk .exercise_item .plan_list ul li .info_wpr h4, .tab .exercise_item .plan_list ul li .info_wpr h4{
        font-size: 15px;
        line-height: 22px;
    }
    .exercise_item .plan_list ul li .info_wpr h6{
        font-size: 9px;
        line-height: 12px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    .desk .exercise_item .plan_list ul li .info_wpr h6, .tab .exercise_item .plan_list ul li .info_wpr h6{
        font-size: 13px;
        line-height: 18px;
    }
    .exercise_item .plan_list ul li .info_wpr h6 span{
        padding: 0 5px 0 3px;
    }
    .exercise_item .plan_list ul li .info_wpr h6 span:not(:last-child){
        border-right: 1px solid #d9d9d9;
    }
    .exercise_item .plan_list ul li .superset{
        position: absolute;
        width: 16px;
        height: 35px;
        word-break: break-all;
        right: 10px;
        bottom: -25px;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 4px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
        display: flex;
        align-items: center;
        z-index: 1;
    }
    .exercise_item .plan_list ul li:last-child .superset{
        display: none;
    }
    .video_wpr{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    :deep(.section_item .video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
    }

    :deep(.section_item .video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    /*------- details --------*/
    .exercise_details .ex_title{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 20px;
        position: relative;
    }
    .exercise_details .ex_title .back_btn{
        font-size: 15px;
        color: #999;
        position: absolute;
        left: 0;
        top: 5px;
        cursor: pointer;
    }
    .exercise_details .info_wpr{
        display: flex;
        align-items: center;
        justify-content:space-between
    }
    .exercise_details .info_wpr h6{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .desk .exercise_details .info_wpr h6, .tab .exercise_details .info_wpr h6{
        font-size: 13px;
        line-height: 16px;
        padding: 15px 0;
        font-weight: 400;
    }
    .exercise_details .info_wpr h6 span{
        padding: 4px 10px;
        background: #e9e9e9;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
    }
    .desk .exercise_details .info_wpr h6 span, .tab .exercise_details .info_wpr h6 span{
        padding: 7px 15px;
        background: #e9e9e9;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
    }

    .exercise_details .instruction, .exercise_list .instruction{
        padding: 30px 15px 0 15px;
    }
    .desk .exercise_details .instruction, .desk .exercise_list .instruction, .tab .exercise_details .instruction, .tab .exercise_list .instruction{
        padding: 50px 15px 0 15px;
    }
    .exercise_details .instruction h4.headline, .exercise_list .instruction h4.headline, .task_details h4.headline{
        width: 100%;
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 12px;
        position: relative;
        display: flex;
        text-align: left;
    }
    .exercise_list .instruction h4:before {
        position: absolute;
        height: 20px;
        content: '';
        left: -15px;
        top: 0;
        border-left: 3px solid var(--var-element-color);
    }
    .timer .exercise_details .instruction h4:before, .timer .exercise_list .instruction h4:before{
        display: none;
    }

    .desk .exercise_details .instruction h4.headline, .desk .exercise_list .instruction h4.headline, .tab .exercise_details .instruction h4.headline, .tab .exercise_list .instruction h4.headline{
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 15px;
    }
    .desk .exercise_details .instruction h4:before, .desk .exercise_list .instruction h4:before, .tab .exercise_details .instruction h4:before, .tab .exercise_list .instruction h4:before{
        height: 22px;
    }

    .exercise_details button.calc_btn{
        height: 24px;
        padding: 0 10px;
        background: #f5f5f5;
        font-size: 11px;
        line-height: 14px;
        border-radius: 3px;
        flex-grow: 0;
        margin: 0 -15px 0 auto;
        position: relative;
    }
    .desk .exercise_details button.calc_btn, .tab .exercise_details button.calc_btn{
        height: 30px;
        font-size: 13px;
        line-height: 16px;
    }
    .exercise_details button.calc_btn.blink{
        background: #fff;
    }
    .exercise_details button.calc_btn.blink:after {
        position: absolute;
        content: '';
        border-radius: 3px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--var-element-color);
        animation: clr_anim 0.5s infinite alternate;
    }
    .exercise_details .instruction h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        text-align:center;
        padding-bottom: 10px;
    }
    .desk .exercise_details .instruction h4, .tab .exercise_details .instruction h4{
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 20px;
        position: relative;
    }
    .exercise_details .instruction label{
        text-align: center;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        display: block;
        padding-bottom: 5px;
    }
    .desk .exercise_details .instruction label, .tab .exercise_details .instruction label{
        font-size: 15px;
        line-height: 22px;
        padding-bottom: 10px;
    }
    .exercise_details .instruction h5,
    .task_details h5{
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 10px 0 15px 0;
        text-align: left;
    }
    .desk .exercise_details .instruction h5, .tab .exercise_details .instruction h5{
        font-size: 15px;
        line-height: 22px;
    }
    .exercise_details .instruction p, .exercise_list .instruction p, .task_details p{
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: left;
    }
    .desk .exercise_details .instruction p, .desk .exercise_list .instruction p, .tab .exercise_details .instruction p, .tab .exercise_list .instruction p{
        font-size: 15px;
        line-height: 23px;
    }
    .exercise_details .instruction .video_wpr{
        border-radius: 6px;
    }

    .activity{
        width: 100%;
        margin: 15px 0 0 0;
        padding-top: 15px;
    }
    .activity table {
        width: 100%;
        border-spacing: 8px;
        background: var(--var-block-bg-color);
        border-radius: 5px;
        padding: 0 5px 5px 5px;
    }
    .desk .activity table, .tab .activity table{
        padding: 0 10px 10px 10px;
    }
    .activity table th, .activity table td{
        padding: 10px 0;
        border-radius: 3px;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
    }
    .desk .activity table th, .desk .activity table td, .tab .activity table th, .tab .activity table td{
        font-size: 13px;
        line-height: 18px;
    }
    .activity table th{
        width: 23%;
    }
    .activity table td{
        width: 23%;
        background: #fff;
        color: #777;
    }
    .activity table th.set{
        width: 40px;
    }
    .activity table td.set {
        width: 40px;
        background: var(--var-block-bg-color);
    }
    .activity table td input{
        width: 100%;
        text-align: center;
        font-size: 11px;
        background: #fff;
        color: #777;
    }
    .activity table td input:disabled{
        opacity: 1;
    }
    .activity table td input::placeholder{
        color: #777;
        opacity: 1;
    }
    .activity table td input:-ms-input-placeholder {
        color: #777;
        opacity: 1;
    }
    .activity table td input::-ms-input-placeholder {
        color: #777;
        opacity: 1;
    }
    .desk .activity table td input, .tab .activity table td input{
        font-size: 13px;
    }
    .activity table td input[type=number].no_spinner{
        width: 25px;
    }
    .activity table td input[type=number].no_spinner::-webkit-outer-spin-button,
    .activity table td input[type=number].no_spinner::-webkit-inner-spin-button{
        -webkit-appearance: none;
    }
    .exercise_details .action_wpr, .task_details .action_wpr{
        width: 100%;
        gap: 15px;
        position: relative;
        margin: 30px 0 20px 0;
    }
    .exercise_details button, .task_details button{
        flex-grow: 1;
        height: 40px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 20px;
        cursor: pointer;
    }
    .desk .exercise_details button, .tab .exercise_details button{
        height: 55px;
        font-size: 16px;
        line-height: 20px;
    }
    .exercise_details .incomplete_btn, .task_details .incomplete_btn{
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }
    .exercise_details .complete_btn, .task_details .complete_btn {
        border: 1px solid var(--var-submit-btn-color);
        background: var(--var-submit-btn-color);
        color: var(--var-submit-btn-txt-color);
    }
    .incomplete_sec{
        position: absolute;
        width: 300px;
        padding: 5px 15px;
        bottom: 100%;
        left: 0;
        margin-bottom: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 4;
    }
    .incomplete_sec:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: 50px;
        top: calc(100% - 1px);
    }
    .incomplete_sec .action_wpr{
        gap: 10px;
        justify-content: flex-end;
        margin: 5px 0 15px 0;
    }
    .incomplete_sec button{
        flex-grow: 0;
        height: 30px;
        border-radius: 3px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        padding: 0 15px;
    }
    .incomplete_sec .cancel_rsn{
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }
    .incomplete_sec .save_rsn{
        border: 1px solid #2c3e50;
        background: #2c3e50;
        color: #fff;
    }


    /*-------- Complete -------*/
    .workout_completion{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .desk .workout_completion, .tab .workout_completion{
        padding-top: 30px;
    }
    .finish_timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
    }
    .desk .finish_timer, .tab .finish_timer{
        padding-bottom: 30px;
    }
    .finish_timer h2{
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #121525;
        padding: 10px 0;
        border-bottom: 1px solid #d9d9d9;
    }
    .total_timer{
        display: flex;
        gap: 10px;
        font-size: 22px;
        line-height: 28px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
    }
    .total_timer label{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .total_timer label span{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .ratings ul{
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
    }
    .ratings ul li{
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        padding: 5px 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .desk .ratings ul li, .tab .ratings ul li{
        gap: 20px;
        padding: 10px 0;
        font-size: 15px;
        line-height: 20px;
    }
    .ratings ul li span {
        display: flex;
        flex-direction: row-reverse;
        gap: 5px;
        color: var(--var-element-color);
    }

    /* ----- for ratings ---------- */

    .ratings ul li span input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
    }
    .desk .ratings ul li span input, .tab .ratings ul li span input {
        width: 20px;
        height: 20px;
    }
    .ratings ul li span input::before {
        content: '\f005';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 13px;
        color: #dbdbdb;
        transition: 0.5s;
    }
    .desk .ratings ul li span input::before, .tab .ratings ul li span input::before {
        font-size: 15px;
    }
    .ratings input:hover ~ input::before,
    .ratings input:hover::before,
    .ratings input:checked ~ input::before,
    .ratings input:checked::before {
        color: var(--var-element-color);
    }
    .workout_completion .setting_wpr{
        max-width: 300px;
        width: 100%;
    }
    .desk .workout_completion .setting_wpr, .tab .workout_completion .setting_wpr{
        max-width: 400px;
    }
    .workout_completion .field_wpr textarea{
        height: 100px;
    }
    .desk .workout_completion .field_wpr textarea, .tab .workout_completion .field_wpr textarea{
        height: 150px;
    }


    /*-------- Complete -------*/

    .workoutLogged{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .workoutLogged .success {
        color: var(--var-element-color);
        font-size: 30px;
        padding: 15px 0 30px 0;
    }
    .workoutLogged h4{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 5px;
    }
    .desk .workoutLogged h4, .tab .workoutLogged h4{
        font-size: 22px;
        line-height: 35px;
        padding-bottom: 15px;
    }
    .workoutLogged p{
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .desk .workoutLogged p, .tab .workoutLogged p{
        font-size: 15px;
        line-height: 22px;
    }
    .workout_name{
        padding: 15px 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 30px 0 15px;
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0));
        max-width: 250px;
        width: 100%;
        text-align: center;
    }
    .desk .workout_name, .tab .workout_name{
        padding: 20px 25px;
        max-width: 350px;
        font-size: 18px;
        line-height: 25x;
    }
    .reminder{
        padding: 15px 0;
    }
    .reminder_day{
        padding: 15px 0;
    }
    .reminder_day label{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        padding: 5px 0;
        cursor: pointer;
    }
    .reminder_day label span {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid var(--var-element-color);
        position: relative;
        margin-top: 3px;
    }

    .reminder_day label span:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: var(--var-element-color);
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .reminder_day label input[type=radio]:checked ~ span:after{
        transform: scale(1);
    }
    .time_line{
        max-width: 250px;
        width: 100%;
    }
    .workoutLogged .form_grp :deep(.multiselect), .workoutLogged .form_grp .field_wpr input{
        height: 35px;
        min-height: 35px;
    }
    .workoutLogged .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 75px;
    }
    .workoutLogged :deep(.multiselect-single-label){
        padding: 0 20px 0 15px;
    }
    .workoutLogged :deep(.multiselect-caret){
        margin-right: 10px;
    }
    .workoutLogged :deep(.multiselect-single-label-text){
        font-size: 11px;
    }
    .desk .week_days_list label span.weekdays,
    .tab .week_days_list label span.weekdays {
        color: var(--var-element-text-color);
    }

    @keyframes clr_anim{
        0% {
            opacity: 0.05;
        }
        100% {
            opacity: 0.2;
         }
    }
    @keyframes clr_anim2{
        0% {
            opacity: 0.2;
        }
        100% {
            opacity: 0.5;
         }
    }
    @keyframes mover {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10px);
         }
    }
    @keyframes mover2 {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-7px);
         }
    }

    .dashboard .rep_confirmation {
        position: sticky;
        bottom: 0;
    }

    .info_wpr .v_tag {
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }
    .cell .exercise_details .instruction h4.headline, .cell .exercise_list .instruction h4.headline{
        font-size: 16px;
        line-height: 23px;
    }
    .more_details{
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebebeb;
        border-radius: 15px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }
    .user_heading{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        text-align: center;
        justify-content: center;
    }
    .label_heading{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .cell .exercise_list{
        min-height: 495px;
        padding-bottom: 30px;
    }
    .cell .dashboard_footer{
        width: auto;
    }

    .cell .form_area_video {
        max-width: 800px;
        width: 100%;
        margin: 0px;
        flex: 1;
    }

    .form_area_video {
        max-width: 800px;
        margin: 20px auto 50px auto;
        flex: 1;
    }

    .complete_btn:disabled {
        /* opacity: .6 */
        background: var(--var-submit-btn-disabled-color);
        border-color: var(--var-submit-btn-disabled-color);
    }


    .activity thead th,
    .activity .set {
        color: var(--var-element-text-color);
    }

    .flex-space-between {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }
    .dashboard_content .form_area_video .section_item {
        padding: 0px 0px 25px 0px;
    }
    .cell .empty_section h1{
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        line-height: 22px;
    }

    .video_wpr_flip {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
        transform: scale(-1, 1);
    }

    .block_list{
        width: 100%;
        padding-top: 15px;
    }
    .block_list li{
        padding: 30px 0;
    }
    .block_list li:not(:last-child){
        border-bottom: 1px solid #e9e9e9;
    }
    .block_item h4{
        display: flex;
        justify-content: flex-start;
    }
    .block_item h4 img{
        max-height: 30px;
        width: auto;
        margin-right: 12px;
        filter: grayscale(1);
    }
    .block_item h4 i{
        cursor: pointer;
        width: 18px;
        height: 18px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        color: #7e7e7e;
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }
    .block_item.show h4 i{
        transform: rotate(-180deg);
    }
    .block_item h4 .title{
        font-size: 16px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .block_item h4 .block_type{
        padding: 2px 10px;
        height: auto;
        flex-grow: 0;
        background: #e9e9e9;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        margin-left: 10px;
    }
    .block_item h4 .plan_info{
        padding-top: 10px;
        width: 100%;
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .block_item .exercise_items{
        padding-top: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .block_item.show .exercise_items{
        padding-top: 20px;
        overflow: auto;
        max-height: max-content;
    }
    .exercise_items .item {
        background: #f5f5f5;
        border-radius: 6px;
        display: flex;
        cursor: pointer;
        margin-bottom: 12px;
    }
    .exercise_items .item .info_wpr{
        flex: 1 1 auto;
        padding: 10px;
    }
    .exercise_items .item .info_wpr h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 7px;
        text-align:left;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .exercise_items .item .info_wpr h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
    }
    .exercise_items .item .info_wpr .start_exercise_btn{
        font-size: 11px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
        height: auto;
        display: none;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }
    .exercise_items .item .info_wpr .start_exercise_btn i{
        font-size: 9px;
        padding-left: 5px;
    }
    .exercise_items .item:hover .info_wpr .start_exercise_btn{
        display: flex;
    }
    .exercise_items .item:hover .info_wpr h6{
        display: none;
    }
    .exercise_items .item .video_area{
        width: 33%;
        mix-blend-mode: darken;
    }
    .exercise_items .item .video_wpr{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
    }
    .exercise_items .item .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .rest_alert.has_play_pause_button {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
</style>